import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';

const radioHorizontalOffset = 32;

export const StyledContainer = styled.div`
  padding-left: ${rem(radioHorizontalOffset)};
  min-height: ${rem(20)};
  display: flex;
  align-items: center;
`;

export const StyledRadioInput = styled.input<{ theme: 'primary' | 'secondary' | 'checkmark' }>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  margin-left: ${rem(-26)};
  height: ${rem(13)};
  width: ${rem(13)};

  & + label:after {
    opacity: 0;
    content: '';
    display: inline-block;
    position: absolute;
    left: ${rem(8 - radioHorizontalOffset)};
    top: 50%;
    transform: translateY(-50%);
    width: ${rem(8)};
    height: ${rem(8)};
    border-radius: ${rem(8)};
    margin-right: ${rem(10)};
    background: ${variables.colors.primary};
    vertical-align: sub;
    ${(props) =>
      props.theme === 'secondary' &&
      css`
        background: ${variables.colors.lighterBlack};
      `}

    ${(props) =>
      props.theme === 'checkmark' &&
      css`
        left: ${rem(-radioHorizontalOffset)};
        content: url('/icons/validation-24px.svg');
        width: ${rem(24)};
        height: ${rem(24)};
        background: none;
      `}
  }

  &:checked + label:after {
    opacity: 1;
  }

  &:focus + label {
    &:before {
      outline: ${rem(2)} solid ${variables.colors.highlight};
      outline-offset: ${rem(2)};
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: default;

      & + label:after {
        display: none;
      }

      & + label {
        color: ${variables.colors.mediumGray};
        cursor: default;

        &:before {
          cursor: default;
          border-color: ${variables.colors.mediumGray};
        }
      }
    `}
`;

export const StyledRadioLabel = styled.label<{ bold: boolean }>`
  position: relative;
  cursor: pointer;
  font-size: ${rem(13)};
  font-family: ${(props) => (props.bold ? variables.fonts.fontGothamMedium : variables.fonts.fontGothamBook)};
  color: ${variables.colors.lighterBlack};
  line-height: ${rem(20)};

  &:before {
    box-sizing: border-box;
    content: '';
    display: inline-block;
    position: absolute;
    left: ${rem(-radioHorizontalOffset)};
    top: 50%;
    transform: translateY(-50%);
    width: ${rem(24)};
    height: ${rem(24)};
    border-radius: ${rem(24)};
    border: ${rem(1)} solid ${variables.colors.darkGray};
    margin-right: ${rem(10)};
    background: ${variables.colors.white};
    vertical-align: sub;
  }
`;

export const StyledRadioInfo = styled.span``;
