import styled from 'styled-components';
import variables from '@styles/variables';
import GridCol from '@molecules/GridCol/GridCol';
import { rem } from 'polished';
import Config from '@config';
import Link from '@atoms/Link/Link';

export const StyledImageGridComponent = styled(GridCol)`
  z-index: 1;
  width: 100%;
  background-color: ${variables.colors.white};
  overflow: hidden;
  position: relative;
  align-self: center;
  border: ${rem(1)} solid ${variables.colors.mediumGray};
  border-radius: ${rem(8)};
  margin: 0;
  box-sizing: content-box;
  height: ${rem(392)};

  ${Config.MEDIA.IS_MOBILE_XSMALL} {
    border-radius: 0;
  }

  ${Config.MEDIA.FROM_MOBILE} {
    height: ${rem(404)};
  }

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    height: ${rem(430)};
  }
`;

export const StyledImage = styled.img`
  position: absolute;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
`;

export const StyledLink = styled(Link)`
  display: block;
  height: 100%;
  width: 100%;
  color: unset !important;
`;
