import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import GridCol from '@molecules/GridCol/GridCol';
import { rem } from 'polished';
import Link from '@atoms/Link/Link';
import Grid from '@atoms/Grid/Grid';
import Product from '@molecules/Product/Product';
import Config from '@config';

export const StyledSmallGridComponent = styled(GridCol)`
  z-index: 1;
  width: calc(100% - ${rem(1)});
  border: ${rem(1)} solid ${variables.colors.mediumGray};
  border-radius: ${rem(8)};
  margin: ${rem(-1)} ${rem(-1)} 0 ${rem(-1)};
  box-sizing: content-box;

  height: ${rem(392)};

  ${Config.MEDIA.FROM_MOBILE} {
    height: ${rem(404)};
  }

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    height: ${rem(430)};
  }
`;

export const StyledGrid = styled(Grid)`
  height: 100%;
  margin: 0;
  gap: 0;
`;

export const StyledProduct = styled(Product)<{ imagePosition: 'LEFT' | 'RIGHT' }>`
  border: 0 !important;
  border-radius: ${rem(8)} 0 0 ${rem(8)};
  box-sizing: border-box !important;
  margin: 0 !important;
  max-width: unset;
  ${(props) =>
    props.imagePosition === 'LEFT' &&
    css`
      border-left: ${rem(1)} solid ${variables.colors.lightGray} !important;
    `}
  ${(props) =>
    props.imagePosition === 'RIGHT' &&
    css`
      border-right: ${rem(1)} solid ${variables.colors.lightGray} !important;
    `}
`;

export const StyledProductCol = styled(GridCol)`
  display: block;
`;

export const StyledImageCol = styled(GridCol)`
  position: relative;
  overflow: hidden;
  z-index: 1;
  border-radius: 0 ${rem(8)} ${rem(8)} 0;
`;

export const StyledImage = styled.img`
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const StyledLink = styled(Link)`
  display: block;
  height: 100%;
  color: unset !important;
`;
