import { StyledLastBuyComponent } from './LastBuyComponent.styles';
import { StyledProductDummy } from '@organisms/BuyAllProductsComponent/BuyAllProductsComponent.styles';
import Carousel from '@molecules/Carousel/Carousel';
import React, { ReactNode } from 'react';
import { AxfoodProductDetailsViewModel } from '@occ/api-client';
import useProductBannerItems from '@molecules/ProductBeam/useProductBannerItems';
import useSymplifyExperiment from '@utility/ABTest/Symplify/useSymplifyExperiment';
import TrackedComponent from '@organisms/TrackedComponent/TrackedComponent';
import SlimProductCard from '@molecules/SlimProductCard/SlimProductCard';
import useProductImpressionTracking from '@hooks/useProductImpressionsTracking';
import useResponsive from '@hooks/useResponsive';

export interface LastBuyComponentProps {
  data: CMSProductBannerComponentType;
}

const LastBuyComponent = ({ data }: LastBuyComponentProps) => {
  const { component, isLoading } = useProductBannerItems(data.uid, false);
  const variant = useSymplifyExperiment('Do not forget to buy', 'Original');

  const { fromTabletPortrait: fromTablet, fromDesktopLarge } = useResponsive();

  const getAmountOfItemsPerSlide = () => {
    if (fromDesktopLarge) return 3;
    if (fromTablet) return 2;
    return 1;
  };

  const { onProductShown } = useProductImpressionTracking('last-buy-component');

  const getProductElements = (items: Array<AxfoodProductDetailsViewModel>) => {
    const elementsArr: Array<ReactNode> = [];
    items?.forEach((product, i) => {
      elementsArr.push(
        <TrackedComponent
          product={product}
          index={i}
          callback={() => onProductShown(product, i)}
          key={`last-buy-${product.code}`}
        >
          <SlimProductCard
            product={product}
            variant="multisearch"
            category="last-buy-component"
            showBuyButtonForZeroQuantity={variant === 'C'}
          />
        </TrackedComponent>
      );
    });
    return elementsArr;
  };

  return component?.paginationData?.items ? (
    <StyledLastBuyComponent>
      <Carousel
        elements={getProductElements(component?.paginationData?.items)}
        elementsPerSlide={getAmountOfItemsPerSlide()}
        elementDummy={<StyledProductDummy />}
        variant="lastBuy"
        title={data.title}
        key={`${data.title}`}
      />
    </StyledLastBuyComponent>
  ) : null;
};

export default LastBuyComponent;
