import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import Text from '@atoms/Text/Text';
import Heading from '@atoms/Heading/Heading';
import Button from '@atoms/Button/Button';
import variables from '@styles/variables';

export const StyledTwoTilesWrapper = styled.div`
  margin-bottom: ${rem(-1)}; /* Fixes double border issue */

  ${Config.MEDIA.IS_MOBILE} {
    margin-left: ${rem(-variables.size.containerPaddingMobileSmall)};
    margin-right: ${rem(-variables.size.containerPaddingMobileSmall)};

    ${Config.MEDIA.FROM_MOBILE} {
      margin-left: ${rem(-variables.size.containerPaddingMobile)};
      margin-right: ${rem(-variables.size.containerPaddingMobile)};
    }

    border-top: ${rem(1)} solid ${variables.colors.mediumGray};
    border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};
    & + & {
      border-top: none;
    }
  }
`;

export const StyledTileWrapper = styled.div<{
  image?: string;
  backgroundColor?: string;
  first?: boolean;
  clickable?: boolean;
  reducedPadding?: boolean;
}>`
  min-height: ${rem(248)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: ${(props) => props.clickable && 'pointer'};
  background-color: ${variables.colors.white};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: ${rem(20)} ${rem(20)} ${rem(32)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    min-height: ${rem(350)};
    background-color: ${(props) => props.backgroundColor};
    border-top: ${rem(1)} solid ${variables.colors.mediumGray};
    border-right: ${rem(1)} solid ${variables.colors.mediumGray};
    border-left: ${(props) => (!props.first ? 'none' : `${rem(1)} solid ${variables.colors.mediumGray}`)};
    border-bottom: ${rem(1)} solid ${variables.colors.mediumGray};
    height: 100%;
    border-radius: 0 ${rem(40)} ${rem(40)} 0;

    ${(props) =>
      props.first &&
      css`
        border-radius: ${rem(40)} 0 0 ${rem(40)};
      `}
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    min-height: ${rem(310)};
    padding-left: ${rem(60)};
    padding-right: ${rem(60)};

    ${(props) =>
      props.reducedPadding &&
      css`
        padding: ${rem(20)} ${rem(20)} ${rem(32)};
      `}
  }

  ${Config.MEDIA.FROM_DESKTOP_LARGE} {
    min-height: ${rem(346)};
    padding-left: ${rem(100)};
    padding-right: ${rem(100)};

    ${(props) =>
      props.reducedPadding &&
      css`
        padding: ${rem(20)} ${rem(20)} ${rem(32)};
      `}
  }

  ${Config.MEDIA.FROM_DESKTOP_XLARGE} {
    padding-left: ${rem(100)};
    padding-right: ${rem(100)};
  }

  ${(props) =>
    props.image &&
    css`
      background-image: url(${props.image});
    `}
`;

export const StyledText = styled(Text)<{ textColor: string }>`
  margin-bottom: ${rem(20)};
  text-align: center;
  color: ${variables.colors.black} !important;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    color: ${(props) => props.textColor} !important;
  }
`;

export const StyledHeading = styled(Heading)<{ textColor: string }>`
  margin-bottom: ${rem(20)};
  color: ${variables.colors.black};
  text-align: center;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    color: ${(props) => props.textColor};
  }
`;

export const StyledButton = styled(Button)<{ textColor: string; backgroundColor: string }>`
  &&& {
    color: ${variables.colors.white};
    background: ${variables.colors.primary};

    ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
      color: ${(props) => props.textColor};
      background: ${(props) => props.backgroundColor};

      &:hover:not(.disabled) {
        @media (hover: hover) and (pointer: fine) {
          background: ${variables.colors.primaryDark};
        }
      }
    }
  }
`;

export const StyledArrow = styled.div`
  position: absolute;
  bottom: ${rem(-33)};
  width: ${rem(20)};
  height: ${rem(20)};
  background-color: ${variables.colors.lighterGray};
  transform: rotate(45deg);
  z-index: 1;
`;
