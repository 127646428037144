import React from 'react';
import Skeleton from '@components/atoms/Skeleton/Skeleton';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import {
  FooterWrapper,
  ProductContainer,
  ProductInformation,
  ProductWrapper,
  SkeletonPageBeamFilters,
  SkeletonPageBeamWrapper,
  SkeletonProductWrapper,
  SkeletonWrapper,
} from './ProductBeam.skeleton.styles';
import useResponsive from '@hooks/useResponsive';

interface Props {
  type?: 'buyAll' | 'pageBeam' | 'productBeam';
}

const ProductBeamSkeleton = ({ type = 'productBeam' }: Props) => {
  const sideMenuIsOpen = useAppSelector(selectSideNavMenuIsOpen);
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const { fromTabletPortrait, fromTabletLandscape, fromDesktop, fromDesktopLarge, fromDesktopXL } = useResponsive();

  let amount = fromDesktopXL
    ? 7
    : fromDesktopLarge && type === 'pageBeam'
    ? 6
    : fromDesktop
    ? 5
    : fromTabletPortrait
    ? fromTabletLandscape
      ? 4
      : 3
    : 2;

  if (fromDesktop && !fromDesktopXL) {
    if (sideMenuIsOpen) {
      amount -= 1;
    }

    if (isCartPreviewOpen) {
      amount -= 1;
    }
  } else if (fromDesktopXL) {
    if (sideMenuIsOpen && isCartPreviewOpen) {
      amount -= 1;
    }
  }

  const skeletonProduct = () => {
    const productArray = [];
    for (let i = 0; i < amount; i += 1) {
      productArray.push(
        <ProductContainer key={`${amount}-${i}`}>
          <ProductWrapper>
            <Skeleton type="rect" height={167} />
            <ProductInformation>
              <Skeleton type="rect" height={16} width="70%" />
              <Skeleton type="rect" height={16} width="50%" />
            </ProductInformation>
            <Skeleton type="button" height={46} />
          </ProductWrapper>
        </ProductContainer>
      );
    }
    return productArray;
  };

  return (
    <SkeletonWrapper pageBeam={type === 'pageBeam'}>
      {type === 'pageBeam' && (
        <SkeletonPageBeamWrapper>
          <Skeleton type="rect" height={36} width={400} />
          <SkeletonPageBeamFilters>
            <Skeleton type="button" height={40} width={120} />
            <Skeleton type="button" height={40} width={120} />
          </SkeletonPageBeamFilters>
        </SkeletonPageBeamWrapper>
      )}

      <SkeletonProductWrapper data-testid="productbeam-skeleton">{skeletonProduct()}</SkeletonProductWrapper>

      {type !== 'pageBeam' && (
        <FooterWrapper>
          {type !== 'buyAll' && (
            <>
              <Skeleton type="rect" height={16} width={98} />
              <Skeleton type="rect" height={8} width={180} />
            </>
          )}
          <Skeleton type="button" height={52} width={110} />
        </FooterWrapper>
      )}
    </SkeletonWrapper>
  );
};

export default ProductBeamSkeleton;
