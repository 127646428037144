import { useMediaQuery } from 'react-responsive';
import { useSelector } from 'react-redux';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import useResponsive from '@hooks/useResponsive';

export const useAmountOfItemsPerSlide = () => {
  const sideMenuIsOpen = useSelector(selectSideNavMenuIsOpen);
  const isCartPreviewOpen = useSelector(selectMiniCartPreviewIsOpen);

  const { fromTabletPortrait, fromTabletLandscape, fromDesktop, fromDesktopLarge, fromDesktopXL } = useResponsive();

  const fromDesktopLarge_large = useMediaQuery({
    query: '(min-width: 1626px)',
  });

  const fromDesktop_large = useMediaQuery({
    query: '(min-width: 1389px)',
  });

  if (fromDesktopXL) {
    if (sideMenuIsOpen || isCartPreviewOpen) return 6;
    return 7;
  }

  if (fromDesktopLarge_large) {
    if (sideMenuIsOpen && isCartPreviewOpen) return 4;
    if (sideMenuIsOpen || isCartPreviewOpen) return 5;
  }

  if (fromDesktopLarge) {
    if (sideMenuIsOpen && isCartPreviewOpen) return 4;
    if (sideMenuIsOpen || isCartPreviewOpen) return 5;
    return 6;
  }

  if (fromDesktop_large) {
    if (sideMenuIsOpen || isCartPreviewOpen) return 4;
    return 5;
  }

  if (fromDesktop) {
    if (sideMenuIsOpen && isCartPreviewOpen) return 3;
    if (sideMenuIsOpen || isCartPreviewOpen) return 4;
    return 5;
  }

  if (fromTabletLandscape) return 4;
  if (fromTabletPortrait) return 3;
  return 2;
};
