import { rem } from 'polished';
import { css } from 'styled-components';

const DesktopScrollbar = css`
  @media (hover: hover) and (pointer: fine) {
    @supports selector(::-webkit-scrollbar) {
      padding: ${rem(1)} ${rem(6)} ${rem(1)} ${rem(1)};

      ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: ${rem(4)};
      }

      ::-webkit-scrollbar-thumb {
        border-radius: ${rem(4)};
        background-color: rgba(0, 0, 0, 0.2);
        box-shadow: 0 0 ${rem(1)} rgba(255, 255, 255, 0.5);
      }
    }
  }
`;

export default DesktopScrollbar;
