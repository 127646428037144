import { useEffect, useState } from 'react';
import useUserAgent from '@hooks/useUserAgent';
import ColumnComponent from '@molecules/ColumnComponent/ColumnComponent';
import { StyledMultiColumnContainerComponent } from './MultiColumnContainerComponent.styles';
import type { ComponentWsDTO, ContentSlotWsDTO } from '@occ/api-client';

interface Props {
  data: ComponentWsDTO;
  context?: SlotContextType;
}

interface CmsComponentContentSlotWsDTO extends ContentSlotWsDTO {
  cmsComponents: string;
  cmsComponentTypeCodes: string;
  uuid: string;
  widthPercent: string;
}
export interface ColumnContainerContentSlotWsDTO extends ComponentWsDTO {
  columnContainerData: Array<CmsComponentContentSlotWsDTO> | CmsComponentContentSlotWsDTO;
  hideInApp: 'true' | 'false';
  hideInWeb: 'true' | 'false';
}

const MultiColumnContainerComponent = ({ data, context }: Props) => {
  const contentSlot = data as ColumnContainerContentSlotWsDTO;
  const [showContainer, setShowContainer] = useState({});
  const { isNativeApp } = useUserAgent();

  const contentSlotArray =
    !!contentSlot.columnContainerData && !Array.isArray(contentSlot.columnContainerData)
      ? [contentSlot.columnContainerData]
      : contentSlot.columnContainerData;

  const slotArrayHasContent = contentSlotArray?.some((slot) => slot.cmsComponents);

  useEffect(() => {
    if (isNativeApp === undefined) return;
    setShowContainer(isNativeApp ? contentSlot.hideInApp === 'false' : contentSlot.hideInWeb === 'false');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNativeApp]);

  return (
    <>
      {showContainer && contentSlotArray?.length > 0 && slotArrayHasContent && (
        <StyledMultiColumnContainerComponent>
          {contentSlotArray.map(
            (child) => child.cmsComponents && <ColumnComponent key={child.uuid} column={child} context={context} />
          )}
        </StyledMultiColumnContainerComponent>
      )}
    </>
  );
};

export default MultiColumnContainerComponent;
