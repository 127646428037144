import { useEffect } from 'react';
import debounce from '@helpers/debounce';
import { ResizeObserver, ResizeObserverEntry } from '@juggle/resize-observer';
import Config from '@config';

const getGridTemplateColumns = (element: Element) => {
  const styles = window.getComputedStyle(element);
  return styles?.gridTemplateColumns.split(' ').map((d) => Math.round(parseFloat(d)));
};

const setAmountOfGridCols = (element: Element, setItemsPerRow: (items: number) => void) => {
  setItemsPerRow(0);
  const gridCols = getGridTemplateColumns(element);
  setItemsPerRow(gridCols.filter((col) => col === gridCols[0]).length);
};

const useGridTemplateColumns = (
  setItemsPerRow: (items: number) => void,
  ref: HTMLDivElement | null,
  isLoading: boolean
) => {
  useEffect(() => {
    let firstRun = true;
    const debouncedSetAmountOfGridCols = debounce((entry) => {
      setAmountOfGridCols(entry, setItemsPerRow);
    }, Config.TIMEOUT.productBeamSetAmountOfGridColsMs);

    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
      if (entries.length) {
        if (firstRun) {
          setAmountOfGridCols(entries[0].target, setItemsPerRow);
          firstRun = false;
        } else {
          debouncedSetAmountOfGridCols(entries[0].target);
        }
      }
    });
    let gridHTMLNode: Element | null;

    if (ref && !isLoading) {
      gridHTMLNode = ref?.querySelector("[data-id='grid']");
      gridHTMLNode && resizeObserver.observe(gridHTMLNode);
      gridHTMLNode && setAmountOfGridCols(gridHTMLNode, setItemsPerRow);
    }

    return () => {
      debouncedSetAmountOfGridCols.cancel();
      if (resizeObserver?.unobserve && gridHTMLNode) resizeObserver.unobserve(gridHTMLNode);
    };
  }, [ref, isLoading]);
};

export default useGridTemplateColumns;
