import Config from '@config';
import { rem } from 'polished';
import styled, { css, keyframes } from 'styled-components';
import Carousel from '@molecules/Carousel/Carousel';
import variables from '@styles/variables';

export const show = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: ${rem(999)};
  }
`;

export const hide = keyframes`
  from {
    max-height: ${rem(999)};
  }
  to {
    max-height: 0;
  }
`;

export const StyledFoldoutWrapper = styled.div<{ show: boolean }>`
  ${(props) =>
    props.show &&
    css`
      ${Config.MEDIA.IS_MOBILE} {
        margin-left: ${rem(-variables.size.containerPaddingMobileSmall)};
        margin-right: ${rem(-variables.size.containerPaddingMobileSmall)};

        ${Config.MEDIA.FROM_MOBILE} {
          margin-left: ${rem(-variables.size.containerPaddingMobile)};
          margin-right: ${rem(-variables.size.containerPaddingMobile)};
        }
      }

      // Negative margins to offset the container padding, make gray background extend past horizontal boundaries
      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        margin-left: ${rem(-16)};
        margin-right: ${rem(-16)};
      }
      margin-top: ${rem(24)};
    `}
`;

export const StyledGridWrapper = styled.div<{ hasPagination: boolean }>`
  position: relative;
  min-height: ${rem(393)};

  ${(props) =>
    props.hasPagination &&
    css`
      margin-bottom: ${rem(40)};
    `}

  ${Config.BREAKPOINTS.FROM_MOBILE} {
    min-height: ${rem(405)};
  }

  ${Config.BREAKPOINTS.FROM_TABLET_PORTRAIT} {
    min-height: ${rem(402)};
    border-radius: ${rem(30)};
  }
`;

export const StyledSpinner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const FoldOutAnimation = styled.div<{ show: boolean }>`
  overflow-y: hidden;
  ${(props) =>
    props.show
      ? css`
          max-height: ${rem(999)};
          animation: ${show} ease-out 0.7s forwards;
        `
      : css`
          max-height: ${rem(999)};
          animation: ${hide} ease-out 0.7s forwards;
        `}
`;

export const StyledCarousel = styled(Carousel)`
  background-color: ${variables.colors.lighterGray};

  ${Config.MEDIA.IS_MOBILE} {
    padding-left: ${rem(variables.size.containerPaddingMobileSmall)};
    padding-right: ${rem(variables.size.containerPaddingMobileSmall)};

    ${Config.MEDIA.FROM_MOBILE} {
      padding-left: ${rem(variables.size.containerPaddingMobile)};
      padding-right: ${rem(variables.size.containerPaddingMobile)};
    }
  }
`;
