import { RefObject, useEffect } from 'react';
import Config from '@config';

interface FocusTrapProps {
  enable: boolean;
  trapRef: RefObject<HTMLElement>;
  onEscape?: () => void;
}

const useFocusTrap = ({ enable, trapRef, onEscape }: FocusTrapProps) => {
  const focusOnFirstElement = () => {
    trapRef.current?.querySelector<HTMLElement>(Config.SELECTORS.focusableElements)?.focus();
  };

  useEffect(() => {
    // Focus first element when enable becomes true
    if (enable && trapRef?.current) {
      focusOnFirstElement();
    }

    const onKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onEscape) {
        onEscape();
      }
    };

    const handleFocusChange = (event: FocusEvent) => {
      if (!trapRef?.current?.contains(event.target as Node)) {
        focusOnFirstElement();
      }
    };

    document.addEventListener('focusin', handleFocusChange);
    document.addEventListener('keydown', onKeyDown);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('focusin', handleFocusChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enable, trapRef?.current]);
};

export default useFocusTrap;
