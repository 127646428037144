import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import { rem } from 'polished';
import Config from '@config';

export const StyledHotjarSurveyWidget = styled.div<{ adjustLeft?: boolean }>`
  background-color: ${variables.colors.lighterBlack};
  border-radius: ${rem(2)} ${rem(2)} 0 0;
  transform-origin: center bottom;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(68)};
  height: ${rem(32)};

  position: fixed;
  right: 0;
  top: 50%;
  transform: translate(50%, 0px) rotate(-90deg);

  ${(props) =>
    props.adjustLeft &&
    css`
      right: auto;
      left: 0;
      transform: translate(-50%, 0px) rotate(90deg);
    `};

  ${Config.MEDIA.IS_MOBILE} {
    // Z-index should be below toolbar, since a lot of modals originate from toolbar
    z-index: ${variables.zIndex.toolbar - 1} !important;
  }
`;

export const StyledHotjarSurveyWidgetButton = styled.button`
  border: none;
  color: ${variables.colors.white};
  background-color: transparent;
  cursor: pointer;
  font-family: ${variables.fonts.fontGothamMedium};
  font-size: ${rem(13)};
  line-height: ${rem(16)};
  white-space: nowrap;
`;
