import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import { rem } from 'polished';
import Config from '@config';
import Text from '@atoms/Text/Text';

export const StyledHeader = styled.header<{ showBorder: boolean }>`
  position: relative;
  background-color: ${variables.colors.white};

  padding: 0 ${rem(variables.size.containerPaddingMobileSmall)};
  ${Config.MEDIA.FROM_MOBILE} {
    padding: 0 ${rem(variables.size.containerPaddingMobile)};
  }
  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: 0 ${rem(20)};
  }
  ${Config.MEDIA.BELOW_DESKTOP} {
    padding-right: ${rem(0)};
    position: sticky;
    top: 0;
  }
  display: flex;
  align-items: center;
  height: ${rem(60)};
  width: 100%;
  justify-content: space-between;
  ${(props) =>
    props.showBorder &&
    css`
      border-bottom: ${rem(1)} solid ${variables.colors.lightGray};
    `}
`;

export const StyledHeaderWrapper = styled.div<{ isNativeApp?: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  ${(props) => props.isNativeApp && `justify-content: space-between;`}

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    flex-direction: row;
  }
`;

export const StyledHeaderButtonsWrapper = styled.div<{ isNativeApp?: boolean }>`
  display: flex;
  gap: ${rem(24)};
  margin-right: ${rem(20)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    gap: ${rem(32)};
    margin-right: ${rem(32)};
  }

  // Make sure buttons occupy full screen with in mobile app since logo is not visible there
  ${(props) =>
    props.isNativeApp &&
    css`
      width: 100%;
      justify-content: space-between;
    `}
`;

export const StyledSharedListLabel = styled(Text)`
  margin-right: ${rem(20)};
`;
