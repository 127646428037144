import { ReactNode, useEffect, useRef, useState } from 'react';
import useAbortController from '@hooks/useAbortController';
import useTranslation from 'next-translate/useTranslation';
import { useAppSelector } from '@hooks/useAppDispatch';
import { useMediaQuery } from 'react-responsive';
import { getPromotionProducts } from '@api/interfaces/productApi';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import Product from '@molecules/Product/Product';
import { trackProductImpressionsWithPosition } from '@helpers/analyticsHelpers/trackProductImpressions';
import Config from '@config';
import Carousel, { ItemType } from '@molecules/Carousel/Carousel';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import {
  StyledMixMatchBeam,
  StyledMixMatchBeamArrow,
  StyledMixMatchBeamArrowContainer,
  StyledProductDummy,
  StyledSpinner,
} from './MixMatchBeam.styles';
import type { AxfoodBasicProductViewModel, AxfoodProductDetailsViewModel } from '@occ/api-client';

interface Props {
  product: AxfoodProductDetailsViewModel | AxfoodBasicProductViewModel;
  onClose: () => void;
  listPos: number;
  closeBeam?: boolean;
  containerRef?: HTMLDivElement | null;
  productElement?: HTMLDivElement | null;
}

const MixMatchBeam = ({ product, onClose, listPos, closeBeam, containerRef, productElement }: Props) => {
  const [row, setRow] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const [column, setColumn] = useState<number | null>(null);
  const [amount, setAmount] = useState<number>(0);
  const [showSpinner, setShowSpinner] = useState(true);
  const [visible, setVisible] = useState(false);
  const [productElements, setProductElements] = useState<ReactNode[]>([]);
  const [trackedProducts, setTrackedProducts] = useState<Array<string>>([]);
  const { t } = useTranslation('product');
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const isSideNavMenuOpen = useAppSelector(selectSideNavMenuIsOpen);
  const mixMatchRef = useRef<HTMLDivElement>(null);
  const getSignal = useAbortController();
  const { TOOLBAR_HEIGHT, MARGIN_HEIGHT } = {
    TOOLBAR_HEIGHT: 60,
    MARGIN_HEIGHT: 20,
  };

  const isMobile = useMediaQuery({
    query: Config.BREAKPOINTS.IS_MOBILE,
  });

  let resizeTimeout: any;

  const getAmountOfItemsPerSlide = () => {
    if (containerRef && productElement) {
      return Math.floor(containerRef.offsetWidth / productElement.offsetWidth);
    }
    return 0;
  };

  const getProductElements = (items: Array<AxfoodBasicProductViewModel> = []) =>
    items.map((child) => (
      <Product product={child} key={`mix-match-${child.code}`} variant="mixmatch" disableMixMatchButton />
    ));

  const setRowColumn = (e?: UIEvent, force?: boolean) => {
    if (force || windowWidth !== window.innerWidth) {
      clearTimeout(resizeTimeout);
      setWindowWidth(window.innerWidth);
      setAmount(0);
      resizeTimeout = setTimeout(() => {
        const numberOfProducts = getAmountOfItemsPerSlide();
        setAmount(numberOfProducts);
        const rowValue = Math.ceil(listPos / numberOfProducts) + 1;
        setRow(rowValue);
        const modValue = listPos % numberOfProducts === 0 ? numberOfProducts : listPos % numberOfProducts;
        setColumn((modValue / numberOfProducts - 1 / numberOfProducts / 2) * 100);
      }, Config.TIMEOUT.mixMatchBeamResizeMs);
    }
  };

  const onCloseHandler = () => {
    setVisible(false);
    setTimeout(onClose, Config.TIMEOUT.mixMatchBeamAnimationMs);
  };

  useEffect(() => {
    mixMatchRef.current &&
      window.scrollTo({
        top: mixMatchRef?.current?.offsetTop - (isMobile ? MARGIN_HEIGHT : TOOLBAR_HEIGHT + MARGIN_HEIGHT),
        behavior: 'smooth',
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mixMatchRef.current]);

  useEffect(() => {
    const signal = getSignal();
    setVisible(true);

    const fetchData = async (code: string) => {
      try {
        const res = await getPromotionProducts(
          {
            promotion: code,
            excludeProducts: product.code,
            page: 0,
            pageSize: 100,
          },
          signal
        );
        setProductElements(getProductElements(res.data.items));
        setShowSpinner(false);
      } catch (error) {
        console.error(error);
      }
    };
    if (product.potentialPromotions[0].code) {
      product.potentialPromotions[0].code && fetchData(product.potentialPromotions[0].code);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.addEventListener('resize', setRowColumn);

    return () => {
      window.removeEventListener('resize', setRowColumn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth]);

  useEffect(() => {
    setRowColumn(undefined, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCartPreviewOpen, isSideNavMenuOpen]);

  useEffect(() => {
    if (closeBeam === true) {
      onCloseHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closeBeam]);

  // TODO fix issue with x.item can possibly be null
  const handleSlideShown = (items: ItemType[]) => {
    // @ts-ignore
    const productsElementsToTrack = items.filter((x: ItemType) => !trackedProducts.includes(x.item.props.product.code));
    const productObjectsToTrack = productsElementsToTrack.map((x: ItemType) => {
      // @ts-ignore
      return { product: x.item.props.product, position: x.index + 1 };
    });

    const productCodesToTrack = productObjectsToTrack.map((x) => x.product.code);
    setTrackedProducts([...trackedProducts, ...productCodesToTrack]);
    if (productObjectsToTrack.length > 0) {
      trackProductImpressionsWithPosition(productObjectsToTrack, `multi_product_offer | ${product.code}`);
    }
  };

  return (
    <>
      {amount > 0 && (
        <StyledMixMatchBeam
          ref={mixMatchRef}
          show={visible}
          hide={!visible}
          style={{ gridRow: row, gridColumn: `1 / span ${amount}` }}
        >
          <StyledMixMatchBeamArrowContainer>
            <StyledMixMatchBeamArrow style={{ left: `${column}%` }} />
          </StyledMixMatchBeamArrowContainer>
          <>
            {showSpinner ? (
              <StyledSpinner size={32} />
            ) : (
              <>
                {productElements && (
                  <Carousel
                    elements={productElements}
                    elementsPerSlide={amount}
                    elementDummy={<StyledProductDummy />}
                    variant="mixmatch"
                    onSlideShown={handleSlideShown}
                    title={t('product->mixmatch->label')}
                  />
                )}
              </>
            )}
          </>
        </StyledMixMatchBeam>
      )}
    </>
  );
};

export default MixMatchBeam;
