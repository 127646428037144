import TagManager from 'react-gtm-module';
import { sendMessageToAppViaJavascript } from '@api/web-to-app';
import { AnalyticsEcommerceItemType } from '@helpers/analyticsHelpers/analyticsProduct';
interface GenericDataLayerTrackEvent {
  event: 'Track';
  action?: string;
  [key: string]: any;
}
export enum EcommerceEvent {
  VIEW_CART = 'view_cart',
  BEGIN_CHECKOUT = 'begin_checkout',
  ADD_SHIPPING_INFO = 'add_shipping_info',
  ADD_PAYMENT_INFO = 'add_payment_info',
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart',
  PURCHASE = 'purchase',
  VIEW_LIST_ITEM = 'view_item_list',
  VIEW_ITEM = 'view_item',
  VIEW_PROMOTION = 'view_promotion',
  SELECT_PROMOTION = 'select_promotion',
  REFUND = 'refund',
}

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#add_to_cart
interface EcommerceBaseDataLayerEvent {
  source?: 'ecommerce';
  currency?: 'SEK';
  items: AnalyticsEcommerceItemType[];
  category?: string;
}

export interface EcommerceRemoveFromCartDataLayerEvent {
  event: EcommerceEvent.REMOVE_FROM_CART;
  ecommerce: { cart_id: string } & EcommerceBaseDataLayerEvent;
}

export interface EcommerceAddToCartDataLayerEvent {
  event: EcommerceEvent.ADD_TO_CART;
  ecommerce: { cart_id: string; new_cart: boolean } & EcommerceBaseDataLayerEvent;
}

export interface EcommerceViewCartDataLayerEvent {
  event: EcommerceEvent.VIEW_CART;
  ecommerce: { value: number; cart_id: string } & EcommerceBaseDataLayerEvent;
}

export interface EcommerceBeginCheckoutDataLayerEvent {
  event: EcommerceEvent.BEGIN_CHECKOUT;
  ecommerce: { value: number; coupon?: string } & EcommerceBaseDataLayerEvent;
}

export interface EcommerceAddShippingInfoDataLayerEvent {
  event: EcommerceEvent.ADD_SHIPPING_INFO;
  ecommerce: { value: number; shipping_tier: 'click_and_collect' | 'home_delivery' } & EcommerceBaseDataLayerEvent;
}

export interface EcommerceAddPaymentInfoDataLayerEvent {
  event: EcommerceEvent.ADD_PAYMENT_INFO;
  ecommerce: {
    value: number;
    payment_method: 'Klarna' | 'existing_card' | 'new_card' | 'invoice';
  } & EcommerceBaseDataLayerEvent;
}

interface EcommercePurchaseDataLayerEvent {
  event: EcommerceEvent.PURCHASE;
  ecommerce: {
    value: number;
    transaction_id: string;
    coupon?: number;
    shipping?: number;
    tax?: number;
    store_id?: string;
    delivery_method?: string;
    payment_method?: string;
    cart_id?: string;
  } & EcommerceBaseDataLayerEvent;
}

interface EcommerceRefundDataLayerEvent {
  event: EcommerceEvent.REFUND;
  ecommerce: {
    value: number;
    transaction_id: string;
  } & EcommerceBaseDataLayerEvent;
}

interface EcommerceViewItemListDataLayerEvent {
  event: EcommerceEvent.VIEW_LIST_ITEM;

  ecommerce: {
    item_list_id?: string;
    item_list_name?: string;
  } & EcommerceBaseDataLayerEvent;
}

interface EcommerceViewItemDataLayerEvent {
  event: EcommerceEvent.VIEW_ITEM;

  ecommerce: {
    value: number;
  } & EcommerceBaseDataLayerEvent;
}

interface EcommerceViewSelectPromotionDataLayerEvent {
  event: EcommerceEvent.VIEW_PROMOTION | EcommerceEvent.SELECT_PROMOTION;

  ecommerce: {
    creative_name: string;
    creative_slot: string;
    promotion_id: string;
    source?: 'ecommerce';
    items?: undefined;
    currency?: string;
  };
}

interface EcommerceDataLayerNullEvent {
  ecommerce: null;
  [key: string]: any;
}

export interface DeviceDataLayerEvent {
  event: 'device';
  device: 'App' | 'AppWithConsent' | 'Web';
  [key: string]: any;
}

interface CustomDataLayerEvent {
  event: 'virtualPageView' | 'identify';
  [key: string]: any;
}

type EcommerceDataLayerUnionEvent =
  | EcommerceAddToCartDataLayerEvent
  | EcommerceRemoveFromCartDataLayerEvent
  | EcommerceViewCartDataLayerEvent
  | EcommerceBeginCheckoutDataLayerEvent
  | EcommerceAddShippingInfoDataLayerEvent
  | EcommerceAddPaymentInfoDataLayerEvent
  | EcommercePurchaseDataLayerEvent
  | EcommerceViewItemListDataLayerEvent
  | EcommerceViewItemDataLayerEvent
  | EcommerceViewSelectPromotionDataLayerEvent
  | EcommerceRefundDataLayerEvent;

type DataLayerEvent =
  | GenericDataLayerTrackEvent
  | EcommerceDataLayerNullEvent
  | EcommerceDataLayerUnionEvent
  | DeviceDataLayerEvent
  | CustomDataLayerEvent;
const convertGTMTrackEvent = (dataLayer: GenericDataLayerTrackEvent | EcommerceDataLayerUnionEvent) => {
  if (dataLayer.ecommerce) {
    const { event: name, ecommerce: params } = dataLayer as EcommerceDataLayerUnionEvent;
    return {
      name,
      ...params,
    };
  } else {
    const { action: name, event: _event, ...params } = dataLayer as any;
    return {
      name,
      ...params,
    };
  }
};

const pushGTMEvent = (dataLayer: DataLayerEvent) => {
  const isGenericTrackingEvent = dataLayer.event === 'Track' && dataLayer?.action!;
  const isEcommerceTrackingEvent = dataLayer.ecommerce && dataLayer.event!;
  if (window.trackInFirebaseAnalytics && (isGenericTrackingEvent || isEcommerceTrackingEvent)) {
    const data = convertGTMTrackEvent(dataLayer as GenericDataLayerTrackEvent);
    sendMessageToAppViaJavascript({
      event: 'track_in_firebase_analytics',
      data,
    });
  } else {
    TagManager.dataLayer({
      dataLayer,
    });
  }
};

export const pushGTMEcommerceEvent = (dataLayer: EcommerceDataLayerUnionEvent) => {
  if (!window.trackInFirebaseAnalytics) pushGTMEvent({ ecommerce: null });
  pushGTMEvent({
    ...dataLayer,
    ecommerce: {
      source: 'ecommerce',
      currency: 'SEK',
      ...dataLayer.ecommerce,
    },
  } as EcommerceDataLayerUnionEvent);
};

export default pushGTMEvent;
