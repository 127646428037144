import packageComponentHelper from '@helpers/packageComponentHelper';
import Product from '@molecules/Product/Product';
import useResponsiveCMSMedia from '@hooks/useResponsiveCMSMedia';
import {
  StyledGrid,
  StyledImage,
  StyledImageCol,
  StyledLink,
  StyledProductCol,
  StyledSmallGridComponent,
  StyledProduct,
} from './SmallGridPackageComponent.styles';
import GridComponentTitleAndButton from '@molecules/GridComponentTitleAndButton/GridComponentTitleAndButton';

interface Props {
  data: SmallGridPackageComponentType;
}

const SmallGridPackageComponent = ({ data }: Props) => {
  const packageHelper = packageComponentHelper();
  const {
    spanLength,
    displayRow,
    displayColumn,
    gridCols,
    imageLinkUrl,
    imageTarget,
    imagePosition,
    buttonTitlePosition,
    title,
    titleColor,
    buttonColor,
    buttonTextColor,
    buttonText,
    enableButton,
  } = data || {};
  const { image } = useResponsiveCMSMedia({ media: data.image });

  return (
    <StyledSmallGridComponent
      size={{
        startRow: { s: parseInt(displayRow, 10) },
        startCol: packageHelper.getStartCol(displayColumn, gridCols, parseInt(spanLength, 10)),
        spanCol: packageHelper.getSpanSize(data),
      }}
    >
      <StyledGrid>
        <StyledImageCol
          size={{
            startRow: { s: 1 },
            startCol: packageHelper.alignImageInRelationToProduct(imagePosition),
            spanCol: { s: 1, m: 2, l: 3 },
          }}
        >
          {imageLinkUrl && !enableButton ? (
            <StyledLink
              data-testid="grid-link"
              href={imageLinkUrl}
              target={imageTarget === 'newWindow' ? '_blank' : '_self'}
            >
              <StyledImage src={image.url} alt={image.altText} />

              <GridComponentTitleAndButton
                title={title}
                titleColor={titleColor as any}
                buttonTitlePosition={buttonTitlePosition}
                buttonColor={buttonColor}
                buttonTextColor={buttonTextColor}
                buttonText={buttonText}
                enableButton={enableButton}
                imageLinkUrl={imageLinkUrl}
                imageTarget={imageTarget}
                testId="smallGridPackage"
              />
            </StyledLink>
          ) : (
            <>
              <StyledImage src={image.url} alt={image.altText} />
              <GridComponentTitleAndButton
                title={title}
                titleColor={titleColor}
                enableButton={enableButton}
                buttonTitlePosition={buttonTitlePosition}
                imageLinkUrl={imageLinkUrl}
                imageTarget={imageTarget}
                buttonText={buttonText}
                buttonTextColor={buttonTextColor}
                buttonColor={buttonColor}
                testId="smallGridPackage"
              />
            </>
          )}
        </StyledImageCol>

        {(data.products?.length ?? 0) > 0 && (
          <StyledProductCol
            size={{
              startRow: { s: 1 },
              spanCol: { s: 1 },
            }}
          >
            <StyledProduct product={data.products[0]} imagePosition={imagePosition} />
          </StyledProductCol>
        )}
      </StyledGrid>
    </StyledSmallGridComponent>
  );
};

export default SmallGridPackageComponent;
