import { forwardRef, Ref, useEffect, useState } from 'react';
import Text from '@atoms/Text/Text';
import Config from '@config';
import Icon, { IconProps } from '@atoms/Icon/Icon';
import { StyledLink, StyledLinkWrapper, StyledSnackBar, StyledTextWrapper } from './SnackBar.styles';
import Validation from '@icons/validation.svg';
import { UrlObject } from 'url';
import CloseSmall from '@icons/close_small.svg';
import Button from '@atoms/Button/Button';

export type TIconSnackbar = null | 'valid';

interface Props {
  text: string;
  show: (show: boolean) => void;
  bottomPosition?: number;
  callback?: () => void;
  icon: TIconSnackbar;
  link?: string | UrlObject;
  asProp?: string | UrlObject;
  linkText?: string;
  onClick?: () => void;
  onClose?: () => void;
}

const SnackBar = forwardRef(
  (
    { text, show, callback, bottomPosition, icon, link, linkText, onClick, asProp, onClose }: Props,
    ref: Ref<HTMLDivElement>
  ) => {
    const [visible, setVisibility] = useState<boolean | null>(null);

    useEffect(() => {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setVisibility(false);
          if (onClose) onClose();
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [onClose]);

    useEffect(() => {
      if (visible === null) {
        setVisibility(true);
        setTimeout(() => {
          if (!link) setVisibility(false);
        }, Config.TIMEOUT.snackBarDestroyMs);
      }

      if (visible === false) {
        if (show) {
          setTimeout(() => {
            show(false);
            if (callback) callback();
          }, Config.TIMEOUT.snackBarAnimationMs);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    const icons: {
      [key: string]: IconProps;
    } = {
      valid: { svg: Validation, size: 16, color: 'highlight' },
    };

    return (
      <StyledSnackBar slideIn={visible} bottomPosition={bottomPosition} ref={ref}>
        <StyledTextWrapper role="status">
          <Text type="body" size="small" color="white">
            {text}
          </Text>
          {icon && icons[icon] && <Icon {...icons[icon]} />}
        </StyledTextWrapper>
        {link && (
          <StyledLinkWrapper>
            <StyledLink
              href={link}
              asProp={asProp}
              onClick={(e) => {
                if (onClick) {
                  // prevent router to navigate and instead do whatever is passed in to onClick
                  e.preventDefault();
                  onClick();
                }
              }}
            >
              {linkText}
            </StyledLink>
            <Button
              theme="transparent"
              onClick={() => {
                if (onClose) onClose();
                setVisibility(false);
              }}
              data-testid="snackbar-close"
            >
              <Icon svg={CloseSmall} size={12} color="white" />
            </Button>
          </StyledLinkWrapper>
        )}
      </StyledSnackBar>
    );
  }
);

SnackBar.displayName = 'SnackBar';

export default SnackBar;
