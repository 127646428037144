import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Config from '@config';

export const StyledGrid = styled.div<{ isPdp: boolean }>`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0 0 ${rem(16)};

  max-width: calc(100% + ${rem(2 * variables.size.containerPaddingMobileSmall)});
  gap: ${rem(12)};

  ${Config.MEDIA.IS_MOBILE_XSMALL} {
    margin: 0 ${rem(-variables.size.containerPaddingMobileSmall)} ${rem(16)};
    gap: 0;
  }

  ${Config.MEDIA.FROM_MOBILE} {
    margin: 0 auto ${rem(16)};
    max-width: 100%;
  }

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto ${rem(26)};
  }

  ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
    grid-template-columns: repeat(auto-fill, minmax(${rem(223)}, 1fr));
  }

  ${Config.MEDIA.FROM_DESKTOP} {
    grid-template-columns: repeat(auto-fill, minmax(${rem(190)}, 1fr));
    gap: ${rem(16)};
  }

  ${(props) =>
    props.isPdp &&
    css`
      margin: 0 0 ${rem(26)} 0;
      padding: 0 ${rem(variables.size.containerPaddingMobileSmall)};

      ${Config.MEDIA.FROM_MOBILE} {
        padding: 0 ${rem(variables.size.containerPaddingMobile)};
      }

      ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
        grid-template-columns: repeat(4, 1fr);
      }
    `}
`;
