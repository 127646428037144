import Text from '@atoms/Text/Text';
import styled from 'styled-components';
import { rem } from 'polished';
import TextArea from '@atoms/TextArea/TextArea';
import Button from '@atoms/Button/Button';
import Config from '@config';
import variables from '@styles/variables';

export const StyledTitle = styled(Text)`
  margin-bottom: ${rem(40)};
`;

export const StyledTextArea = styled(TextArea)<{ height: number }>`
  height: ${(props) => rem(props.height)};
  min-height: ${rem(100)};
  max-height: ${rem(275)};
  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    max-height: ${rem(200)};
  }
`;

export const StyledTextAreaWrapper = styled.div`
  position: relative;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  gap: ${rem(20)};
  margin-top: ${rem(20)};
  flex-direction: column-reverse;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    width: ${rem(96)};
  }
`;

export const SearchResultWrapper = styled.div`
  margin-top: ${rem(60)};
  display: flex;
  flex-direction: column;
  gap: ${rem(68)};

  ${Config.MEDIA.IS_MOBILE_XSMALL} {
    margin-left: ${rem(-variables.size.containerPaddingMobileSmall)};
    margin-right: ${rem(-variables.size.containerPaddingMobileSmall)};
  }
`;

export const StyledNoResultWrapper = styled.div`
  display: flex;
  gap: ${rem(10)};
  align-items: center;
`;

export const StyledMultiSearchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(20)};
  margin-left: auto;
  margin-right: auto;

  ${Config.MEDIA.FROM_DESKTOP} {
    width: ${rem(680)};
  }
  ${Config.MEDIA.FROM_DESKTOP_XLARGE} {
    width: ${rem(968)};
  }
`;
