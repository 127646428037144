import Config from '@config';
import Icon from '@atoms/Icon/Icon';
import useResizeObserver from '@react-hook/resize-observer';
import { CSSProperties, MouseEvent, ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { ActionButton, Arrow, CloseButton, Container, Content, Heading, TextContent } from './Tooltip.styles';
import CloseSmall from '@icons/close_small.svg';
import useTranslation from 'next-translate/useTranslation';

export type PositionType = 'top' | 'bottom' | 'row-left' | 'row-right';
export type AlignType = 'left' | 'center' | 'right';
export type ToolTipType = 'small' | 'medium' | 'large';
export type ThemeType = 'dark' | 'light' | 'white';

interface Props {
  children: ReactNode;
  position: PositionType;
  variant: ToolTipType;
  align: AlignType;
  className?: string;
  theme?: ThemeType;
  heading?: string;
  buttonText?: string;
  onSubmit?: (e: MouseEvent<HTMLButtonElement>) => void;
  onCloseHandler?: (e: MouseEvent<HTMLSpanElement>) => void;
  offsetRef?: RefObject<HTMLElement> | null;
  disableAutoClose?: boolean;
}

const Tooltip = ({
  position,
  align,
  children,
  variant,
  className,
  theme,
  heading,
  buttonText,
  onSubmit,
  onCloseHandler,
  offsetRef = null,
  disableAutoClose,
}: Props) => {
  const color = theme === 'light' || theme === 'white' ? 'black' : 'white';

  const initialArrowOffset = (offsetRef?.current?.clientWidth || 0) / 2;

  const [arrowOffset, setArrowOffset] = useState(initialArrowOffset);
  const [dynamicAlign, setDynamicAlign] = useState<AlignType | null>(null);
  const [dynamicWidth, setDynamicWidth] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('common');

  useEffect(() => {
    setArrowOffset(initialArrowOffset);
  }, [initialArrowOffset]);

  useResizeObserver(offsetRef, (entry) => {
    setArrowOffset(entry.contentRect.width / 2);
  });

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (variant === 'small' && !disableAutoClose) {
      timer = setTimeout(() => {
        if (onCloseHandler) {
          onCloseHandler({} as MouseEvent<HTMLSpanElement>);
        }
      }, Config.TIMEOUT.closeTooltipMs);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const containerRect = containerRef?.current?.getBoundingClientRect();
    if (containerRect) {
      if (containerRect.left < 0) {
        setDynamicAlign('left');
      } else if (window.innerWidth - containerRect.right < 0) {
        setDynamicAlign('right');
      } else {
        setDynamicAlign(align);
      }
    } else {
      setDynamicAlign(align);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const containerRect = containerRef?.current?.getBoundingClientRect();
    if (containerRect && window.innerWidth < 400) {
      if (dynamicAlign === 'right') {
        setDynamicWidth(containerRect.right - 20);
      } else if (dynamicAlign === 'left') {
        setDynamicWidth(window.innerWidth - containerRect.left - 20);
      } else {
        setDynamicWidth(null);
      }
    }
  }, [dynamicAlign]);

  return (
    <Container
      data-testid="tooltip"
      ref={containerRef}
      className={className}
      variant={variant}
      position={position}
      align={dynamicAlign || align}
      arrowOffset={arrowOffset}
      style={{
        ...(dynamicWidth ? { width: dynamicWidth } : {}),
        ...(!dynamicAlign ? { opacity: 0 } : {}),
      }}
    >
      {onCloseHandler && variant !== 'small' && (
        <CloseButton
          onClick={onCloseHandler}
          color={color}
          data-testid="tooltip-close-button"
          aria-label={t('closeTooltipLabel')}
        >
          <Icon svg={CloseSmall} size={12} color={color} />
        </CloseButton>
      )}
      <Arrow position={position} align={dynamicAlign || align} arrowOffset={arrowOffset} theme={theme} />
      <Content variant={variant} theme={theme}>
        {heading && variant === 'large' && <Heading color={color}>{heading}</Heading>}
        <TextContent theme={theme} variant={variant} hasCloseButton={!!onCloseHandler && variant !== 'small'}>
          {children}
        </TextContent>
        {buttonText && variant === 'large' && <ActionButton onClick={onSubmit}>{buttonText}</ActionButton>}
      </Content>
    </Container>
  );
};

export default Tooltip;
