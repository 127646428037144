import { ReactNode } from 'react';
import CmsComponent from '@organisms/CmsComponent/CmsComponent';
import GridContainer from '@organisms/GridContainer/GridContainer';
import NavigationBreadcrumbComponent from '@molecules/NavigationBreadcrumbComponent/NavigationBreadcrumbComponent';
import { StyledContentWrapper, StyledSlot } from './WillysCategoryTemplate.styles';
import type { PageWithSlots } from '@api/interfaces/pageApi';
import collectSlots from '@api/interfaces/collectSlots';
import useSmartEdit from '@hooks/useSmartEdit';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectSideNavMenuIsOpen } from '@slices/menuSlice';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';

interface Props {
  cmsPage: PageWithSlots;
  children?: ReactNode;
  categorySlots: string[];
}

const WillysCategoryTemplate = ({ cmsPage, children, categorySlots }: Props) => {
  const { HeaderCenter, CategoryMainContent, FooterCenter } = collectSlots(cmsPage.slots, categorySlots);
  const { breadcrumbs = [] } = cmsPage;
  const breadCrumbDataArray = Array.isArray(breadcrumbs) ? breadcrumbs : [breadcrumbs];
  const { isSmartEditEnabled } = useSmartEdit();
  const sideMenuIsOpen = useAppSelector(selectSideNavMenuIsOpen);
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);

  return (
    <>
      {(isSmartEditEnabled || HeaderCenter?.component?.length > 0) && (
        <StyledSlot slotData={HeaderCenter} data-testid="headerCenter-slot">
          {HeaderCenter?.component?.map((child) => (
            <CmsComponent key={child.uuid} component={child} />
          ))}
        </StyledSlot>
      )}
      {children && children}
      <GridContainer addPadding={sideMenuIsOpen || isCartPreviewOpen}>
        <StyledContentWrapper>
          {(isSmartEditEnabled || CategoryMainContent?.component?.length > 0) && (
            <StyledSlot slotData={CategoryMainContent} data-testid="categoryMainContent-slot">
              {breadCrumbDataArray.length > 0 && <NavigationBreadcrumbComponent data={breadCrumbDataArray} />}
              {CategoryMainContent?.component?.map((child) => (
                <CmsComponent key={child.uuid} component={child} />
              ))}
            </StyledSlot>
          )}
        </StyledContentWrapper>
      </GridContainer>
      {(isSmartEditEnabled || FooterCenter?.component?.length > 0) && (
        <StyledSlot slotData={FooterCenter} data-testid="footerCenter-slot">
          {FooterCenter?.component?.map((child) => (
            <CmsComponent key={child.uuid} component={child} />
          ))}
        </StyledSlot>
      )}
    </>
  );
};

export default WillysCategoryTemplate;
