import React, { useEffect, useRef, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import useCustomer from '@hooks/useCustomer';
import paths from '@constants/paths';
import { useRouter } from 'next/router';
import trackLinkNavigation from '@helpers/analyticsHelpers/trackLinkNavigation';
import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';
import Icon from '@atoms/Icon/Icon';
import {
  StyledButton,
  StyledButtonText,
  StyledCustomerName,
  StyledIcon,
  StyledLinksWrapper,
  StyledLogoutButton,
  StyledNavLink,
} from './HeaderDropdownMenu.styles';
import useResponsive from '@hooks/useResponsive';
import useFreezeBodyScroll from '@hooks/useFreezeBodyScroll';
import SlideInSection from '@molecules/SlideInSection/SlideInSection';
import MyAccount from '@icons/my-account.svg';
import MyAccountLoggedIn from '@icons/my-account-logged-in.svg';
import Receipt from '@icons/receipt.svg';
import List from '@icons/list.svg';
import { toggleSlideInSection } from '@slices/slideInSectionSlice';
import { useAppDispatch } from '@hooks/useAppDispatch';

const HeaderDropdownMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('common');
  const { customer } = useCustomer();
  const router = useRouter();
  const { fromDesktop, isMobile } = useResponsive();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const dispatch = useAppDispatch();

  useFreezeBodyScroll(isMobile && isOpen, true);

  useEffect(() => {
    if (isOpen) {
      pushGTMEvent({ event: 'Track', category: 'secondary_menu', action: 'menu_opened', label: '', value: 0 });
    }
  }, [isOpen]);

  const handleLogout = async (e: React.MouseEvent) => {
    trackLinkNavigation('header', t('navigation->logOut'));
    pushGTMEvent({ event: 'Track', category: 'user', action: 'logged_out', label: '', value: 0 });
    sessionStorage.setItem('willys.showLoginHint', 'true');
    router.push('/logout');
  };

  const onNavigate = (category: string, linkText: string) => {
    setIsOpen(false);
    trackLinkNavigation(category, linkText);
  };

  return (
    <>
      <StyledButton
        ref={buttonRef}
        type="button"
        onClick={() => setIsOpen(!isOpen)}
        data-testid="header-dropdown-menu-button"
      >
        {fromDesktop && (
          <StyledButtonText
            data-testid="customer-name"
            data-hj-suppress="true"
            aria-live="polite"
            aria-label={t('common:aria->loggedInAs', {
              username: customer?.isB2BCustomer
                ? customer.companyAlternativeName || customer.companyName
                : customer?.firstName,
            })}
          >
            {customer?.isB2BCustomer ? customer.companyAlternativeName || customer.companyName : customer?.firstName}
          </StyledButtonText>
        )}
        {<Icon svg={MyAccountLoggedIn} size={20} aria-label={t('common:aria->loggedIn')} />}
      </StyledButton>

      {isOpen && (
        <SlideInSection
          onClose={() => {
            setIsOpen(false);
            dispatch(toggleSlideInSection(false));
          }}
          closeOnEscape
        >
          <StyledCustomerName type={'label'}>{customer?.firstName}</StyledCustomerName>

          <StyledLinksWrapper>
            <StyledNavLink href={paths.MY_ACCOUNT} onClick={() => onNavigate('header', t('navigation->myAccount'))}>
              <StyledIcon svg={MyAccount} size={20} color="primary" />
              <span>{t('navigation->myAccount')}</span>
            </StyledNavLink>
            <StyledNavLink href={paths.ACCOUNT_ORDERS} onClick={() => onNavigate('header', t('navigation->myOrders'))}>
              <StyledIcon svg={Receipt} size={20} color="primary" />
              <span>{t('navigation->myOrders')}</span>
            </StyledNavLink>
            <StyledNavLink href={paths.MY_LISTS} onClick={() => onNavigate('header', t('navigation->myLists'))}>
              <StyledIcon svg={List} size={20} color="primary" />
              <span>{t('navigation->myLists')}</span>
            </StyledNavLink>
          </StyledLinksWrapper>

          <StyledLogoutButton theme="secondary" size="small" onClick={(e: React.MouseEvent) => handleLogout(e)}>
            {t('navigation->logOut')}
          </StyledLogoutButton>
        </SlideInSection>
      )}
    </>
  );
};

export default HeaderDropdownMenu;
