import { StyledSelectOption, StyledSelectOptionLabel } from './Select.styles';

interface Props {
  option: OptionType;
  onItemClick: (option: OptionType) => void;
  isSelected: boolean;
  className?: string;
}

const Option = ({ option, onItemClick, isSelected, className }: Props) => {
  return (
    <StyledSelectOption
      className={className}
      key={option.value}
      role="option"
      onClick={() => onItemClick(option)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onItemClick(option);
          e.preventDefault();
        }
      }}
      data-testid="select-option"
      tabIndex={0}
    >
      <StyledSelectOptionLabel type="body" color="black" isSelected={isSelected} title={option.label}>
        {option.label}
      </StyledSelectOptionLabel>
    </StyledSelectOption>
  );
};

export default Option;
