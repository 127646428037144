import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Icon from '@atoms/Icon/Icon';
import { PriceLabelVariant } from '@components/molecules/PriceLabel/PriceLabel';
import Config from '@config';

export const StyledProductPrice = styled.div<{
  hasPromotion?: boolean;
  hasLoyaltyPromotion?: boolean;
  hasSegmentedPromotion?: boolean;
  variant: PriceLabelVariant;
}>`
  align-items: center;
  background-color: ${variables.colors.white};
  border: ${rem(1)} solid ${variables.colors.mediumGray};
  border-radius: ${rem(4)};
  color: ${variables.colors.primary};
  display: flex;
  flex-direction: column;
  font-family: ${variables.fonts.fontGothamSerifPrice};
  margin-top: ${rem(24)};
  padding: ${rem(10)};
  position: relative;
  width: auto;

  ${(props) =>
    props.variant === 'list' &&
    css`
      padding: ${rem(2)} ${rem(5)};
      margin-top: ${rem(0)};
      width: fit-content;
      margin-left: auto;

      ${Config.MEDIA.FROM_DESKTOP} {
        padding: ${rem(4)} ${rem(6)};
      }
    `}

  ${(props) =>
    props.hasPromotion &&
    css`
      background-color: ${variables.colors.primary};
      color: ${variables.colors.white};
      border: 0;
    `}

  ${(props) =>
    (props.hasLoyaltyPromotion || props.hasSegmentedPromotion) &&
    css`
      background-color: ${variables.colors.plus};
      color: ${variables.colors.primaryDark};
      border: 0;
    `}
`;

export const StyledProductPriceTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledProductPriceText = styled.span<{ variant: PriceLabelVariant }>`
  font-size: ${rem(40)};
  line-height: ${rem(33)};

  ${(props) =>
    props.variant === 'list' &&
    css`
      font-size: ${rem(24)};
      line-height: ${rem(24)};

      ${Config.MEDIA.FROM_DESKTOP} {
        font-size: ${rem(34)};
        line-height: ${rem(34)};
      }
    `}
`;

export const StyledProductPriceSymbol = styled.span`
  font-size: ${rem(20)};
  line-height: ${rem(17)};
  font-family: ${variables.fonts.fontGothamBook};
`;

export const StyledProductPriceWrapper = styled.div<{ variant: PriceLabelVariant }>`
  line-height: normal;
  display: flex;
  font-size: ${rem(20)};
  flex-direction: column;

  ${(props) =>
    props.variant === 'list' &&
    css`
      font-size: ${rem(17)};
      line-height: ${rem(15)};
      margin-left: ${rem(0)};
      margin-right: ${rem(0)};
    `}
`;

export const StyledProductPriceDecimal = styled.span<{ variant: PriceLabelVariant }>`
  align-self: flex-start;
  margin-top: ${rem(-2)};

  ${(props) =>
    props.variant === 'list' &&
    css`
      letter-spacing: ${rem(-0.2)};
      margin-top: ${rem(0)};
      font-size: ${rem(12)};
      line-height: ${rem(14)};

      ${Config.MEDIA.FROM_DESKTOP} {
        font-size: ${rem(17)};
        line-height: ${rem(19)};
      }
    `}
`;

export const StyledProductPriceUnit = styled.span<{ variant: PriceLabelVariant }>`
  font-family: ${variables.fonts.fontGothamMedium};
  font-size: ${rem(10)};
  align-self: flex-end;

  ${(props) =>
    props.variant === 'list' &&
    css`
      margin-right: ${rem(1)};
      font-size: ${rem(6)};
      line-height: ${rem(6)};

      ${Config.MEDIA.FROM_DESKTOP} {
        font-size: ${rem(9)};
        line-height: ${rem(9)};
      }
    `}
`;

export const StyledProductPriceIcon = styled(Icon)<{ variant: PriceLabelVariant }>`
  margin-top: ${rem(3)};

  ${(props) =>
    props.variant === 'list' &&
    css`
      margin-top: ${rem(0)};

      svg {
        width: ${rem(28)};
        height: auto;
      }

      ${Config.MEDIA.FROM_DESKTOP} {
        svg {
          width: ${rem(32)};
        }
      }
    `}
`;

export const StyledProductPriceUserIcon = styled(Icon)<{ variant: PriceLabelVariant }>`
  margin-right: ${rem(4)};
  margin-top: ${rem(5)};

  svg {
    width: ${rem(16)};
    height: ${rem(28)};
  }

  ${(props) =>
    props.variant === 'list' &&
    css`
      margin-right: ${rem(2)};
      margin-top: ${rem(3)};

      ${Config.MEDIA.BELOW_DESKTOP} {
        svg {
          width: ${rem(10)};
          height: ${rem(21)};
        }
    `}
`;
