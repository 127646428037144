import styled from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';

export const StyledGenericPersonalCard = styled.div`
  height: ${rem(94)};
  flex: 1 0 auto;
  display: flex;
  border-radius: ${rem(8)};
  align-items: center;
  padding: 0 ${rem(16)};
  background-color: ${variables.colors.lighterGray};
  gap: ${rem(8)};
  justify-content: space-between;
`;

export const StyledGenericPersonalCardTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(6)};
`;
