import { ReactElement, CSSProperties, useRef, useState } from 'react';
import { IconColorType, IconProps } from '@atoms/Icon/Icon';
import { useMediaQuery } from 'react-responsive';
import Tooltip, { PositionType, AlignType, ToolTipType, ThemeType } from '@molecules/Tooltip/Tooltip';
import Config from '@config';
import { StyledContainer, StyledContent, StyledIcon } from './IconTooltip.styles';

export interface Props {
  content: string | ReactElement;
  iconProps?: IconProps;
  position?: PositionType;
  align?: AlignType;
  variant?: ToolTipType;
  theme?: ThemeType;
  className?: string;
  color?: IconColorType;
  openOnClick?: boolean;
  disabled?: boolean;
  disableAutoClose?: boolean;
}

const IconTooltip = ({
  content,
  iconProps,
  className,
  color,
  position = 'top',
  align = 'center',
  variant = 'small',
  theme = 'dark',
  openOnClick,
  disabled = false,
  disableAutoClose,
}: Props) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);
  const isTouch = useMediaQuery({
    query: Config.DEVICE.IS_TOUCH,
  });

  // TOUCH: Toggles show/hide on press (using focus) and hides on blur
  // MOUSE: Toggles show/hide on mouseEnter/MouseLeave
  // KEYBOARD: Toggles show/hide on focus/blur

  return (
    <StyledContainer
      className={className}
      // Desktop events
      onMouseEnter={(e) => {
        if (!disabled && !isTouch && !openOnClick) setShow(true);
      }}
      onMouseLeave={(e) => {
        if (!disabled && !isTouch && !openOnClick) setShow(false);
      }}
      onClick={(e) => {
        if (!disabled && openOnClick) setShow(true);
      }}
      // Accessible events
      onFocus={(e) => {
        if (!disabled) setShow(true);
      }}
      onBlur={(e) => {
        if (!disabled) setShow(false);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !disabled) setShow(true);
        if (e.key === 'Escape') setShow(false);
      }}
      tabIndex={0}
    >
      <StyledContent data-testid="icon-tooltip" ref={ref}>
        {iconProps?.svg && <StyledIcon {...iconProps} color={color} disabled={disabled} />}
        {show && (
          <Tooltip
            onCloseHandler={
              openOnClick
                ? (e) => {
                    setShow(false);
                    e.stopPropagation();
                  }
                : undefined
            }
            variant={variant}
            align={align}
            position={position}
            theme={theme}
            offsetRef={ref}
            disableAutoClose={disableAutoClose}
          >
            {content}
          </Tooltip>
        )}
      </StyledContent>
    </StyledContainer>
  );
};

export default IconTooltip;
