import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import { rem } from 'polished';
import Autocomplete from '@molecules/Autocomplete/Autocomplete';
import Spinner from '@molecules/Spinner/Spinner';
import DesktopScrollbar from '@styles/mixins/desktopScrollbar';

export const StyledAutoComplete = styled(Autocomplete)<{ errorMessage: string }>`
  overflow-y: auto;
  height: 100%;
  // Add padding to the right to prevent the scrollbar from overlapping the content
  padding-right: ${rem(10)} !important;
  width: calc(100% + ${rem(10)});

  ${DesktopScrollbar}

  ${(props) =>
    props.errorMessage &&
    css`
      top: ${rem(-17)};
    `}
`;

export const StyledAutoCompleteContainer = styled.div`
  position: relative;
  z-index: ${variables.zIndex[2]};
  width: 100%;
  min-height: 0;

  ${StyledAutoComplete} {
    padding: 0;
    box-shadow: none;
  }
`;

export const StyledEmptySlot = styled.div`
  text-align: center;
  color: ${variables.colors.darkerGray};
  font-size: ${rem(13)};
  line-height: ${rem(20)};
  padding: 0;
`;

export const StyledEmptySlotContainer = styled.div`
  margin-bottom: ${rem(10)};
`;

export const StyledEmptySlotHead = styled.div`
  padding: ${rem(12)};
  margin-bottom: ${rem(20)};
  font-size: ${rem(13)};
  font-family: ${variables.fonts.fontGothamBook};
  color: ${variables.colors.black};
  background: ${variables.colors.highlightBg};
`;

export const StyledSpinner = styled(Spinner)`
  height: ${rem(80)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
