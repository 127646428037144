import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Config from '@config';
import Text from '@atoms/Text/Text';
import Button from '@atoms/Button/Button';
import { AlignType, PositionType, ThemeType, ToolTipType } from './Tooltip';

const arrowSize = 12;
const arrowOffset = 20;
const arrowDiagonalSize = (arrowSize * 1.414) / 2;
const arrowSizeDiff = 2;

export const Container = styled.span<{
  variant: ToolTipType;
  position: PositionType;
  align: AlignType;
  arrowOffset: number;
}>`
  display: block;
  position: absolute;

  a {
    color: ${variables.colors.white};
    text-decoration: underline;

    &:hover {
      color: ${variables.colors.white};
    }
  }

  ${(props) =>
    props.variant === 'small' &&
    css`
      z-index: ${variables.zIndex.modal + 1};
      width: ${rem(204)};
    `};
  ${(props) =>
    props.variant === 'medium' &&
    css`
      z-index: ${variables.zIndex.modal + 1};
      width: ${rem(280)};

      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        width: ${rem(336)};
      }
    `};
  ${(props) =>
    props.variant === 'large' &&
    css`
      z-index: ${variables.zIndex.mobileMenu + 1};
      width: ${rem(280)};

      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        width: ${rem(336)};
      }
    `};

  ${(props) =>
    props.position === 'bottom' &&
    css`
      bottom: ${rem(0)};
      transform: translateY(calc(100% + ${rem(arrowSize)}));
      ${props.align === 'center' &&
      css`
        transform: translateX(-50%) translateY(calc(100% + ${rem(arrowSize)}));
      `};
    `};

  ${(props) =>
    props.position === 'top' &&
    css`
      top: ${rem(0)};
      transform: translateY(calc(-100% - ${rem(arrowSize)}));
      ${props.align === 'center' &&
      css`
        transform: translateX(-50%) translateY(calc(-100% - ${rem(arrowSize)}));
      `};
    `};

  ${(props) =>
    props.align === 'left' &&
    css`
      left: ${rem(props.arrowOffset > arrowOffset ? -arrowSize / 2 : 0 - arrowOffset / 2 + 2)};
    `};

  ${(props) =>
    props.align === 'center' &&
    css`
      left: 50%;
    `};

  ${(props) =>
    props.align === 'right' &&
    css`
      right: ${rem(props.arrowOffset > arrowOffset ? 0 : 0 - arrowOffset / 2 - 2)};
    `};

  ${(props) =>
    props.position === 'row-left' &&
    css`
      right: ${rem(arrowOffset + arrowSize)};
      top: 50%;
      transform: translateY(-50%);
      bottom: auto;
    `};
  ${(props) =>
    props.position === 'row-right' &&
    css`
      right: ${rem(arrowOffset + arrowSize)};
      top: 50%;
      transform: translateY(-50%);
      bottom: auto;
    `};
`;

export const Content = styled.span<{
  variant: ToolTipType;
  theme: ThemeType;
}>`
  display: block;
  position: relative;
  white-space: normal;
  padding: ${rem(20)};
  background-color: ${variables.colors.lighterBlack};
  box-shadow: 0 ${rem(4)} ${rem(10)} 0 rgba(0, 0, 0, 0.5);
  border-radius: ${rem(8)};
  text-transform: none;
  text-align: left;
  ${(props) =>
    props.theme === 'light' &&
    css`
      background-color: ${variables.colors.plus};
    `};
  ${(props) =>
    props.theme === 'white' &&
    css`
      background-color: ${variables.colors.white};
    `};
  ${(props) =>
    props.variant === 'large' &&
    css`
      padding: ${rem(24)};

      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        padding: ${rem(24)} ${rem(60)} ${rem(24)} ${rem(24)};
      }
    `};
`;

export const CloseButton = styled.button`
  position: absolute;
  right: ${rem(20)};
  top: ${rem(20)};
  z-index: ${variables.zIndex[1]};
  cursor: pointer;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
`;

export const ActionButton = styled(Button)`
  min-height: ${rem(40)};
`;

export const Heading = styled(Text).attrs((props) => ({
  type: props.type || 'label',
}))`
  margin: 0;
`;

export const TextContent = styled.span<{
  theme: ThemeType;
  variant: ToolTipType;
  hasCloseButton: boolean;
}>`
  display: block;
  font-family: ${variables.fonts.fontGothamBook};
  color: ${variables.colors.white};
  font-size: ${rem(13)};
  line-height: ${rem(20)};
  // resets the visual padding in the box by offsetting
  // the line-height at the top and bottom of the text
  margin-top: ${rem(-4)};
  margin-bottom: ${rem(-4)};

  ${(props) =>
    (props.theme === 'light' || props.theme === 'white') &&
    css`
      color: ${variables.colors.lighterBlack};
    `};

  ${(props) =>
    props.hasCloseButton &&
    props.theme !== 'white' &&
    css`
      padding-right: ${rem(28)};

      ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
        padding-right: ${rem(36)};
      }
    `};

  ${(props) =>
    props.variant === 'large' &&
    css`
      margin: ${rem(10)} 0 ${rem(15)} 0;
    `};
`;

export const Arrow = styled.span<{
  position: PositionType;
  align: AlignType;
  theme: ThemeType;
  arrowOffset: number;
}>`
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: ${rem(arrowSize)};
  max-height: ${rem(arrowSize)};
  background-color: ${variables.colors.lighterBlack};

  ${(props) =>
    props.theme === 'light' &&
    css`
      background-color: ${variables.colors.plus};
    `};

  ${(props) =>
    props.theme === 'white' &&
    css`
      background-color: ${variables.colors.white};
    `};

  ${(props) =>
    props.position === 'bottom' &&
    css`
      top: ${rem(-1)};
      transform: rotate(45deg) translateY(-50%);
    `};
  ${(props) =>
    props.position === 'top' &&
    css`
      bottom: ${rem(-1)};
      transform: rotate(-45deg) translateY(50%);
    `};

  ${(props) =>
    props.align === 'left' &&
    css`
      left: ${rem((props.arrowOffset < arrowOffset ? props.arrowOffset : arrowOffset) - arrowSizeDiff)};
    `};
  ${(props) =>
    props.align === 'center' &&
    css`
      left: calc(50% - ${rem(arrowDiagonalSize + arrowSizeDiff)});
    `};
  ${(props) =>
    props.align === 'right' &&
    css`
      right: ${rem((props.arrowOffset > arrowOffset ? props.arrowOffset : arrowOffset) - arrowSizeDiff)};
    `};

  ${(props) =>
    props.position === 'row-left' &&
    css`
      right: 0;
      top: 50%;
      transform: translate(${rem(arrowSize / 2)}, -50%) rotate(45deg);
    `};
  ${(props) =>
    props.position === 'row-right' &&
    css`
      left: 0;
      top: 50%;
      transform: translate(${rem(arrowSize / 2)}, -50%) rotate(45deg);
    `};
`;
