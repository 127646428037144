import React, { RefObject, useEffect, useState, useCallback } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { setInputFieldIsDirtyEvent } from '@helpers/form/events';
import debounce from '@helpers/debounce';
import Config from '@config';

interface Options
  extends Pick<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'maxLength' | 'minLength' | 'pattern' | 'required' | 'type' | 'value'
  > {
  customValidator?: (value: string) => React.ReactNode | string;
  noValidate?: boolean;
  onValidityChange?: (validity: boolean) => void;
}

const useInputValidator = (
  inputRef: RefObject<HTMLInputElement | HTMLTextAreaElement>,
  value = '',
  customValidity = '',
  { noValidate, required, minLength, maxLength, type, customValidator, pattern, onValidityChange }: Options
): [boolean, string | React.ReactNode] => {
  const { t } = useTranslation('common');

  const [isDirty, setIsDirty] = useState(false);

  const [customValidityState, setCustomValidityState] = useState<React.ReactNode | string>(customValidity || '');

  const defaultValidity = () => {
    if (customValidity) {
      return customValidity;
    }

    if (required && !value) {
      return t('input->invalidRequired');
    }

    if (typeof minLength === 'number' && `${value}`.length < minLength) {
      return t('input->invalidMinLength', { minLength });
    }

    if (typeof maxLength === 'number' && `${value}`.length > maxLength) {
      return t('input->invalidMaxLength', { maxLength });
    }

    if (
      type === 'email' &&
      !`${value}`.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      return t('input->invalidEmail');
    }

    if (customValidator) {
      return customValidator(value);
    }

    if (pattern && !`${value}`.match(new RegExp(pattern))) {
      return t('input->invalidPattern');
    }

    return '';
  };

  const debouncedSetIsDirty = debounce(() => {
    setIsDirty(true);
  }, Config.TIMEOUT.inputValidatorSetIsDirtyMs);

  useEffect(() => {
    return () => debouncedSetIsDirty.cancel();
  }, []);

  const handleKeyDown = useCallback(() => {
    if (!noValidate && !isDirty) debouncedSetIsDirty();
  }, []);

  useEffect(() => {
    inputRef.current?.addEventListener('keydown', handleKeyDown);
    return () => {
      inputRef.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleBlur = useCallback(() => {
    if (!noValidate && !isDirty) setIsDirty(true);
  }, []);

  useEffect(() => {
    inputRef.current?.addEventListener('keydown', handleBlur);
    return () => {
      inputRef.current?.removeEventListener('keydown', handleBlur);
    };
  }, [handleBlur]);

  const checkValidity = () => {
    const message = defaultValidity();

    setCustomValidityState(message);
    inputRef.current?.setCustomValidity(message as string);
  };

  const debouncedCheckValidity = debounce(() => {
    checkValidity();
  }, Config.TIMEOUT.inputValidatorCheckValidityMs);

  useEffect(() => {
    if (!noValidate) checkValidity();
    return () => debouncedCheckValidity.cancel();
  }, [required]);

  useEffect(() => {
    if (!noValidate) debouncedCheckValidity();
    return () => debouncedCheckValidity.cancel();
  }, [value, customValidity, inputRef.current, t('input->invalidRequired')]);

  useEffect(() => {
    const handler = (e: ReturnType<typeof setInputFieldIsDirtyEvent>) => {
      setIsDirty(e.detail);
    };
    inputRef.current?.addEventListener('isDirty', handler as EventListener);
    return () => inputRef.current?.removeEventListener('isDirty', handler as EventListener);
  }, [inputRef.current]);

  useEffect(() => {
    if (onValidityChange) {
      onValidityChange(customValidityState === '');
    }
  }, [onValidityChange, customValidityState]);

  return [isDirty, customValidityState];
};

export default useInputValidator;
