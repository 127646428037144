import useTranslation from 'next-translate/useTranslation';
import Text from '@atoms/Text/Text';
import { StyledDRITable, StyledHeaderText } from './DailyRecommendedIntakeTable.styles';
import { ProductInfoNutrientHeaderData } from '@occ/api-client';
import { Fragment } from 'react';

interface Props {
  data: ProductInfoNutrientHeaderData[];
}

const DailyRecommendedIntakeTable = ({ data }: Props) => {
  const { t } = useTranslation('product');

  return (
    <>
      {data.length > 0 && (
        <>
          <StyledHeaderText type="label" size="small">
            {t('product->nutrition->title')}
          </StyledHeaderText>

          {data.map((nutrition: any) => {
            return (
              <Fragment key={`${nutrition.nutrientBasisQuantity}-${nutrition.nutrientBasisQuantityMeasurementUnitCode}`}>
                <Text type="subhead" size="medium">
                  {t('product->nutrition->description', {
                    amount: `${nutrition.nutrientBasisQuantity} ${nutrition.nutrientBasisQuantityMeasurementUnitCode}`,
                  })}
                </Text>
                <StyledDRITable>
                  <thead>
                    <tr>
                      <td></td>
                      <td align="right">
                        <Text type="body" size="small" color="gray">
                          {t('product->nutrition->quantity')}
                        </Text>
                      </td>
                      <td align="right" style={{ width: '90px' }}>
                        <Text type="body" size="small" color="gray">
                          {t('product->nutrition->driPercentage')}
                        </Text>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {nutrition.nutrientDetails.map((detail: any) => {
                      return (
                        <tr
                          key={`${detail.quantityContained}-${detail.nutrientTypeCode}-${detail.dailyValueIntakePercent}`}
                        >
                          <td>{detail.nutrientTypeCode}</td>
                          <td align="right">{`${detail.quantityContained} ${detail.measurementUnitCode}`}</td>
                          <td align="right">
                            {detail.dailyValueIntakePercent ? `${detail.dailyValueIntakePercent}%` : '-'}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </StyledDRITable>
              </Fragment>
            );
          })}
        </>
      )}
    </>
  );
};

export default DailyRecommendedIntakeTable;
