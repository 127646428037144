import variables from '@styles/variables';
import { rem } from 'polished';
import styled, { css, keyframes } from 'styled-components';
import Config from '@config';
import Spinner from '@molecules/Spinner/Spinner';

const show = keyframes`
  from {
    max-height: 0;
  }
  to {
    max-height: ${rem(999)};
  }
`;

const hide = keyframes`
  from {
    max-height: ${rem(999)};
  }
  to {
    max-height: 0;
  }
`;

export const StyledMixMatchBeam = styled.div<{ show: boolean; hide: boolean }>`
  margin: ${rem(20)} 0 0 0;
  position: relative;
  width: calc(100% - ${rem(1)});
  background-color: ${variables.colors.lighterGray};

  ${Config.MEDIA.IS_MOBILE} {
    margin: ${rem(20)} ${rem(-20)} 0;
    width: calc(100% + ${rem(40)});
  }

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    border-radius: ${rem(30)};
    margin: ${rem(20)} ${rem(-16)} ${rem(40)};
    width: auto;
  }

  ${(props) =>
    props.show &&
    css`
      animation: ${show} ease-out 0.7s forwards;
    `}

  ${(props) =>
    props.hide &&
    css`
      animation: ${hide} ease-in 0.7s forwards;
    `}
`;

export const StyledMixMatchBeamArrowContainer = styled.div`
  position: relative;
`;

export const StyledMixMatchBeamArrow = styled.span`
  width: ${rem(20)};
  height: ${rem(20)};
  background-color: ${variables.colors.lighterGray};
  position: absolute;
  top: ${rem(-10)};
  transform: translateX(-50%) rotate(45deg);
`;

export const StyledProductDummy = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: ${variables.colors.white};
  width: calc(100% - ${rem(1)});
`;

export const StyledSpinner = styled(Spinner)`
  margin: ${rem(20)} auto;
  display: flex;
`;
