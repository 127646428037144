import variables from '@styles/variables';
import { StyledIconButton, StyledIconSpan } from './Icon.styles';
import React, { memo } from 'react';

type IconType = React.FC<React.SVGProps<SVGSVGElement> & { title?: string }>;
export type IconColorType = keyof typeof variables.colors;

export interface IconProps {
  svg: IconType;
  'data-testid'?: string;
  title?: string;
  className?: string;
  color?: IconColorType;
  customColor?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size?: number;
  'aria-label'?: string;
}

const Icon = ({ svg: Svg, size = 28, color: _color, customColor, className, title, onClick, ...props }: IconProps) => {
  const color = customColor || (_color ? variables.colors[_color] : variables.colors.lighterBlack);

  if (onClick) {
    return (
      <StyledIconButton
        data-testid={props['data-testid'] || 'icon'}
        aria-label={props['aria-label']}
        type="button"
        onClick={onClick}
        className={className}
        title={title}
        style={{ color }}
        size={size}
      >
        <Svg title={title} />
      </StyledIconButton>
    );
  }

  return (
    <StyledIconSpan
      data-testid={props['data-testid'] || 'icon'}
      className={className}
      style={{ color }}
      size={size}
      aria-label={props['aria-label']}
    >
      <Svg title={title} />
    </StyledIconSpan>
  );
};

export default memo(Icon);
