import { StyledHotjarSurveyWidget, StyledHotjarSurveyWidgetButton } from './HotjarSurveyWidget.styles';
import { useAppSelector } from '@hooks/useAppDispatch';
import { selectMiniCartPreviewIsOpen } from '@slices/miniCartSlice';
import { selectSlideInSectionIsOpen } from '@slices/slideInSectionSlice';
import useTranslation from 'next-translate/useTranslation';
import useCustomRouter from '@hooks/useCustomRouter';
import paths from '@constants/paths';
import useResponsive from '@hooks/useResponsive';

const HotjarSurveyWidget = () => {
  const { t } = useTranslation('common');
  const router = useCustomRouter();
  const { isMobile } = useResponsive();

  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const isSlideInSectionOpen = useAppSelector(selectSlideInSectionIsOpen);

  const adjustLeft = isMobile && router.asPath?.startsWith(paths.MY_LISTS);
  const hide = isCartPreviewOpen || isSlideInSectionOpen;

  const openSurvey = () => {
    window?.hj && window.hj('event', 'show_survey');
  };

  return (
    <>
      {!hide && (
        <StyledHotjarSurveyWidget adjustLeft={adjustLeft}>
          <StyledHotjarSurveyWidgetButton onClick={openSurvey} aria-label={t('survey->buttonAriaLabel')}>
            {t('survey->buttonText')}
          </StyledHotjarSurveyWidgetButton>
        </StyledHotjarSurveyWidget>
      )}
    </>
  );
};

export default HotjarSurveyWidget;
