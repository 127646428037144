import styled from 'styled-components';
import { rem } from 'polished';
import Config from '@config';
import { StyledMarginWrapper } from '@organisms/CmsComponent/CmsComponent.styles';
export const StyledMultiColumnContainerComponent = styled.div`
  display: flex;
  width: 100%;
  gap: ${rem(20)};
  grid-auto-rows: 1fr;
  flex-direction: column;
  justify-content: space-between;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    flex-direction: row;
    gap: 0;
  }

  margin-bottom: ${rem(40)};
  ${Config.MEDIA.FROM_DESKTOP} {
    margin-bottom: ${rem(60)};
  }

  ${StyledMarginWrapper} {
    ${Config.MEDIA.IS_MOBILE} {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
