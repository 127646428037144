import { convertProductsToAnalyticsEcommerceItems } from './analyticsProduct';
import { EcommerceEvent, pushGTMEcommerceEvent } from '@helpers/analyticsHelpers/pushGTMEvent';
import type { AxfoodCartProductViewModel } from '@occ/api-client';

const trackRemoveFromCart = (products: AxfoodCartProductViewModel[], listName: string, cartCode: string) => {
  const items = convertProductsToAnalyticsEcommerceItems(products, undefined, listName);

  pushGTMEcommerceEvent({
    event: EcommerceEvent.REMOVE_FROM_CART,
    ecommerce: {
      cart_id: cartCode,
      items,
    },
  });
};

export default trackRemoveFromCart;
