import { ReactNode, useEffect, useRef, useState } from 'react';
import Product from '@molecules/Product/Product';
import debounce from '@helpers/debounce';
import Config from '@config';
import { type AxfoodBasicProductViewModel } from '@occ/api-client';
import { useAmountOfItemsPerSlide } from '@hooks/useAmountOfItemsPerSlide';

const useProductCarousel = (show: boolean, ref: any, listName: string, extraDeps = []) => {
  const [amountOfSlides, setAmountOfSlides] = useState(0);
  const [productElements, setProductElements] = useState<ReactNode[]>([]);
  const timeoutRef = useRef<NodeJS.Timeout>();
  const amountOfItemsPerSlide = useAmountOfItemsPerSlide();

  // The list of product components to be rendered
  const makeProductElements = (items: Array<AxfoodBasicProductViewModel>) => {
    const elementsArr: Array<ReactNode> = [];

    items.forEach((child) => {
      elementsArr.push(
        <Product
          product={child}
          key={`mix-match-${child.code}`}
          variant="mixmatch"
          disableMixMatchButton
          category={listName}
        />
      );
    });

    setProductElements(elementsArr);
  };

  const recalculateSlides = (_show: boolean) => {
    const amount = Math.ceil(productElements.length / amountOfItemsPerSlide);
    setAmountOfSlides(amount);
  };

  const debouncedCalculateSlides = debounce(recalculateSlides, Config.TIMEOUT.productCarouselCalculateSlidesMs);

  useEffect(() => {
    // Assure products are counted on snow fetches
    if (!amountOfSlides) {
      if (timeoutRef?.current) clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        window.dispatchEvent(new Event('resize'));
      }, Config.TIMEOUT.productCarouselResizeMs);
    }
    return () => {
      if (timeoutRef?.current) clearTimeout(timeoutRef.current);
    };
  }, [productElements, amountOfSlides]);

  useEffect(() => {
    const localCalculateSlides = () => debouncedCalculateSlides(true);
    window.addEventListener('resize', localCalculateSlides);

    return () => {
      window.removeEventListener('resize', localCalculateSlides);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show, debouncedCalculateSlides, ...extraDeps]);

  return { amountOfSlides, amountOfItemsPerSlide, productElements, recalculateSlides, makeProductElements };
};

export default useProductCarousel;
