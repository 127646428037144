import React, {
  ErrorInfo,
  forwardRef,
  MouseEvent,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import trackOpenMixMatchBeam from '@helpers/analyticsHelpers/trackOpenMixMatchBeam';
import { getValidProductLabels } from '@helpers/productHelper';
import slugify from '@helpers/slugify';
import Link from '@atoms/Link/Link';
import Text from '@atoms/Text/Text';
import ProductQuantityInputField from '@molecules/ProductQuantityInputField/ProductQuantityInputField';
import MixMatchBeam from '@molecules/MixMatchBeam/MixMatchBeam';
import PriceLabel from '@molecules/PriceLabel/PriceLabel';
import EnergyLabel from '@atoms/EnergyLabel/EnergyLabel';
import ErrorBoundary from '@utility/ErrorBoundary/ErrorBoundary';
import logger from '@logger';
import { isOfflineProduct, isOfflinePromotion } from '@helpers/typeGuards/product';
import paths from '@constants/paths';
import ProductLabelSplash from '@molecules/ProductLabelSplash/ProductLabelSplash';
import Button from '@atoms/Button/Button';
import {
  StyledImageWrapper,
  StyledLowestHistoricalPrice,
  StyledProduct,
  StyledProductAddToList,
  StyledProductComparePrice,
  StyledProductEnergyWrapper,
  StyledProductFooter,
  StyledProductFooterBottomSection,
  StyledProductHeader,
  StyledProductLinkWrapper,
  StyledProductManufacturerBrand,
  StyledProductManufacturerVolumeWrapper,
  StyledProductName,
  StyledProductOriginalPrice,
  StyledProductPriceWrapper,
  StyledProductPromotionPriceInfo,
  StyledProductPromotionsPreviewCode,
  StyledProductSavePrice,
  StyledProductUsedPromotion,
  StyledProductZCodes,
  StyledProductZCodesIcon,
  StyledMixMatchButton,
} from './Product.styles';
import getProductLabelSplashType from '@helpers/getProductLabelSplashType';
import type { AxfoodBasicProductViewModel, AxfoodProductDetailsViewModel } from '@occ/api-client';
import { useProductImage } from '@hooks/useProductImage';
import AddToListComponent from '@organisms/AddToListComponent/AddToListComponent';
import { makeSingleOfflineEntry, makeSingleOnlineEntry } from '@helpers/wishlistProductEntries';
import webToAppApi from '@api/web-to-app';
import trackShoppingList from '@helpers/analyticsHelpers/trackShoppingList';
import Icon from '@atoms/Icon/Icon';
import AddToList from '@icons/add-to-list.svg';
import useSymplifyExperiment from '@utility/ABTest/Symplify/useSymplifyExperiment';
import AccordionDown from '@icons/accordion-down_small.svg';
import AccordionUp from '@icons/accordion-up_small.svg';

export type Variant = 'product' | 'mixmatch' | 'multisearch';

export interface ProductProps {
  product: AxfoodProductDetailsViewModel | AxfoodBasicProductViewModel;
  category?: string;
  offline?: boolean;
  variant?: Variant;
  listPos?: number;
  openMixMatchBeam?: string;
  setOpenMixMatchBeam?: (code: string) => void;
  containerRef?: HTMLDivElement | null;
  disableMixMatchButton?: boolean;
  hideAddToList?: boolean;
  className?: string;
}

const errorHandler = (code: string) => (error: Error, info: ErrorInfo) => {
  logger.error({
    error: `Product (${code}) Error boundary caught error`,
    message: error.message,
    ...info,
  });
};

const Product = forwardRef<HTMLDivElement, ProductProps>(
  (
    {
      product,
      category,
      offline,
      variant = 'product',
      listPos = 0,
      openMixMatchBeam,
      setOpenMixMatchBeam,
      containerRef,
      disableMixMatchButton = false,
      hideAddToList = false,
      className,
    }: ProductProps,
    ref
  ) => {
    const router = useRouter();
    const { t } = useTranslation('product');
    const promotion = product?.potentialPromotions[0];
    const hasMixMatch = promotion?.realMixAndMatch === true;
    const hasSavePriceLabel = !!promotion?.conditionLabel?.length;
    const hasPromotion = !!promotion;
    const hasSegmentedPromotion = promotion?.campaignType === 'SEGMENTED';
    const hasComparePrices = (product?.comparePrice !== '' && product?.comparePriceUnit) || null;
    const hasPromotionComparePrices = !!promotion?.comparePrice;
    const [isPromotionUsed, setIsPromotionUsed] = useState(false);
    const isPromotionPage = router.pathname === '/erbjudanden' || router.pathname === '/erbjudanden/[mode]';
    const isPromotionPreviewPage = router.pathname.indexOf('/preview') !== -1;
    const lowestHistoricalPrice = promotion?.lowestHistoricalPrice;
    const { productImageURL, productImageAltText, onImageLoadError } = useProductImage(product);
    const [addToListModalOpen, setAddToListModalOpen] = useState(false);
    const testVariant = useSymplifyExperiment('MixMatchBeam Button', 'Original');

    const url = `${isPromotionPage || isPromotionPreviewPage ? (offline ? 'offline-' : 'online-') : ''}${slugify(
      product.name
    )}-${offline ? promotion?.code : product.code}`;
    let as = `/produkt/${encodeURI(url)}`;

    if (isPromotionPreviewPage) {
      as = `/erbjudanden/preview/${encodeURI(url)}`;
    } else if (isPromotionPage) {
      as = `/erbjudanden/${encodeURI(url)}`;
    }

    const query = { ...router.query, name: encodeURIComponent(url), productCode: product.code, showInModal: true };

    const [zCodes, setZCodes] = useState<string[]>([]);
    const [showMixMatchBeam, setShowMixMatchBeam] = useState(false);
    const [startCloseAnimation, setStartCloseAnimation] = useState(false);
    const productRef = useRef<HTMLDivElement | null>(null);
    const [quantity, setQuantity] = useState(0);

    useImperativeHandle(ref, () => productRef.current!, [productRef]);

    useEffect(() => {
      if (openMixMatchBeam === product.code) {
        setShowMixMatchBeam(true);
      } else {
        setShowMixMatchBeam(false);
      }
      if (
        promotion &&
        isOfflinePromotion(promotion) &&
        promotion?.redeemLimit &&
        promotion?.redeemLimit >= 0 &&
        promotion?.timesUsed
      ) {
        setIsPromotionUsed(promotion.timesUsed >= promotion.redeemLimit);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openMixMatchBeam]);

    const mixMatchOnClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (openMixMatchBeam === product.code) {
        setStartCloseAnimation(true);
      } else {
        setStartCloseAnimation(false);
        if (setOpenMixMatchBeam) setOpenMixMatchBeam(product.code);
        trackOpenMixMatchBeam(product.code);
      }
    };

    const productLabelSplash = useMemo(() => {
      const labelSplashType = getProductLabelSplashType(product);

      if (labelSplashType === 'medical') {
        return <ProductLabelSplash type="medical" />;
      } else if (hasSegmentedPromotion) {
        return <ProductLabelSplash type="segmented" isPromotionUsed={isPromotionUsed} />;
      } else if (hasMixMatch && variant !== 'mixmatch' && !disableMixMatchButton) {
        if (testVariant === 'A' || testVariant === 'Original') {
          if (offline) {
            return <ProductLabelSplash type="mixmatch" />;
          }
          return (
            <Button theme="transparent" onClick={mixMatchOnClickHandler}>
              <ProductLabelSplash type="mixmatch" />
            </Button>
          );
        } else if (testVariant === 'B') {
          return (
            <Button theme="link" size="small" onClick={mixMatchOnClickHandler}>
              {t('product->symplifyButtonC')}
            </Button>
          );
        } else {
          return null;
        }
      } else if (labelSplashType) {
        return <ProductLabelSplash type={labelSplashType} />;
      }

      return null;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [disableMixMatchButton, openMixMatchBeam, hasMixMatch, offline, product, variant, testVariant, isPromotionUsed]);

    const closeMixMatch = () => {
      setShowMixMatchBeam(false);
      if (setOpenMixMatchBeam) setOpenMixMatchBeam('');
    };

    useEffect(() => {
      setZCodes(getValidProductLabels(product, 3));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const isPreview = useMemo(() => router.pathname.indexOf(paths.PDP_PREVIEW) !== -1, [router.pathname]);

    const manufacturer = useMemo(() => {
      let str = (product?.manufacturer || '').trim();
      // Fix problem where admins add a period instead of empty string
      if (str === '.') str = '';
      return str;
    }, [product?.manufacturer]);
    const displayVolume = useMemo(() => (product?.displayVolume || '').trim(), [product?.displayVolume]);

    return (
      <ErrorBoundary errorHandler={errorHandler(product.code)}>
        {showMixMatchBeam && (
          <MixMatchBeam
            product={product}
            onClose={closeMixMatch}
            closeBeam={startCloseAnimation}
            listPos={listPos + 1}
            containerRef={containerRef}
            productElement={productRef.current}
          />
        )}
        <StyledProduct
          data-testid="product"
          offline={offline}
          variant={variant}
          className={className}
          itemScope
          itemType="https://schema.org/Product"
          ref={productRef}
        >
          {isPromotionUsed && (
            <StyledProductUsedPromotion>
              <Text type="label">{t('product->promotion->used')}</Text>
            </StyledProductUsedPromotion>
          )}

          <StyledProductHeader>
            <Link
              href={{ pathname: router.pathname, query }}
              nativeAppMethodOverride="replace"
              asProp={{ pathname: as }}
              scroll={false}
              internal
              shallow
              aria-label={`${t('product->openProductDetailLabel')} ${product.name}`}
            >
              <StyledProductLinkWrapper>
                <StyledImageWrapper>
                  <Image
                    priority
                    unoptimized
                    itemProp="image"
                    alt={productImageAltText}
                    src={productImageURL}
                    width="132"
                    height="132"
                    onError={onImageLoadError}
                  />
                </StyledImageWrapper>
              </StyledProductLinkWrapper>
            </Link>
            {!isPreview && !hideAddToList && (
              <StyledProductAddToList>
                <Button
                  data-testid="open-add-to-list-button"
                  onClick={() => setAddToListModalOpen(true)}
                  theme="transparent"
                  aria-label={t('product->addToListLabel')}
                >
                  <Icon svg={AddToList} size={20} color="lighterBlack" />
                </Button>

                <AddToListComponent
                  product={product}
                  tracking={{ parent: 'product_list' }}
                  addToListCallback={({ option, productEntries }) => {
                    webToAppApi.eventShoppingListAddItem(option.id, productEntries[0] as ProductEntryType);
                    trackShoppingList({ action: 'current', parent: 'product_list' });
                  }}
                  createNewListCallback={({ inputValue }) => {
                    webToAppApi.eventShoppingListCreated(inputValue);
                    trackShoppingList({ action: 'new', parent: 'product_list' });
                  }}
                  productEntries={
                    offline && isOfflineProduct(product)
                      ? [makeSingleOfflineEntry(product, quantity)]
                      : [makeSingleOnlineEntry(product, quantity)]
                  }
                  onClose={() => setAddToListModalOpen(false)}
                  isOpen={addToListModalOpen}
                />
              </StyledProductAddToList>
            )}
            <StyledProductPriceWrapper>
              {hasSavePriceLabel &&
                (!lowestHistoricalPrice || lowestHistoricalPrice?.value === product.priceValue || hasMixMatch) && (
                  <StyledProductSavePrice>
                    {hasMixMatch
                      ? promotion?.conditionLabelFormatted
                        ? promotion?.conditionLabelFormatted
                        : promotion?.conditionLabel
                      : promotion?.conditionLabel}
                  </StyledProductSavePrice>
                )}
              <PriceLabel product={product} />
            </StyledProductPriceWrapper>
            {product.energyDeclaration && (
              <StyledProductEnergyWrapper>
                <EnergyLabel
                  energyDeclaration={product.energyDeclaration}
                  onClick={() => {
                    router.push({ pathname: router.pathname, query: { ...query, tab: '3' } }, as, { scroll: false });
                  }}
                />
              </StyledProductEnergyWrapper>
            )}
            <StyledProductZCodes>
              {zCodes.map((child) => (
                <div key={child}>
                  <StyledProductZCodesIcon src={`/icons/labels/${child}.svg`} title={t(`product->label->${child}`)} />
                </div>
              ))}
            </StyledProductZCodes>

            {productLabelSplash}
          </StyledProductHeader>

          <StyledProductFooter>
            <StyledProductName itemProp="name">{product.name}</StyledProductName>

            {(manufacturer || displayVolume) && (
              <StyledProductManufacturerVolumeWrapper>
                <StyledProductManufacturerBrand itemProp="brand">
                  {manufacturer && manufacturer} {displayVolume && displayVolume}
                </StyledProductManufacturerBrand>
              </StyledProductManufacturerVolumeWrapper>
            )}

            <StyledProductFooterBottomSection>
              {hasPromotion && hasPromotionComparePrices && !offline && (
                <StyledProductPromotionPriceInfo>
                  {`${t('product->price->compare')} ${
                    promotion.comparePrice && promotion.comparePrice.indexOf('/') > -1
                      ? promotion.comparePrice
                      : `${promotion.comparePrice}/${product.comparePriceUnit}`
                  }`}

                  {`${promotion?.redeemLimitLabel ? ` • ${promotion?.redeemLimitLabel}` : ''}`}
                </StyledProductPromotionPriceInfo>
              )}
              {hasPromotion && !offline && (
                <StyledProductOriginalPrice>
                  {`${t('product->price->default')} ${product?.priceNoUnit} ${product?.priceUnit}`}
                </StyledProductOriginalPrice>
              )}
              {hasComparePrices && (
                <StyledProductComparePrice>
                  {`${t('product->price->compare')} ${product?.comparePrice}/${product?.comparePriceUnit}`}
                </StyledProductComparePrice>
              )}
              {offline && hasPromotion && (
                <>
                  {isOfflinePromotion(promotion) && promotion?.savePrice && (
                    <StyledProductPromotionPriceInfo>{promotion?.savePrice}</StyledProductPromotionPriceInfo>
                  )}
                  {promotion?.redeemLimitLabel && (
                    <StyledProductOriginalPrice>{promotion?.redeemLimitLabel}</StyledProductOriginalPrice>
                  )}
                </>
              )}
              {!!lowestHistoricalPrice && (
                <StyledLowestHistoricalPrice>
                  {t('product->price->lowestHistorical', {
                    price: lowestHistoricalPrice?.formattedValue,
                  })}
                </StyledLowestHistoricalPrice>
              )}
            </StyledProductFooterBottomSection>
            {!(testVariant === 'C' && hasMixMatch && variant !== 'mixmatch' && !disableMixMatchButton) &&
              !isPreview &&
              !offline && (
                <ProductQuantityInputField
                  product={product}
                  variant="card"
                  category={category}
                  listName={category}
                  quantityCallback={setQuantity}
                  mixmatch={variant === 'mixmatch'}
                />
              )}
            {testVariant === 'C' && hasMixMatch && variant !== 'mixmatch' && !disableMixMatchButton && (
              <StyledMixMatchButton theme="primary" onClick={mixMatchOnClickHandler}>
                {t('product->symplifyButtonC')}
                {showMixMatchBeam ? (
                  <Icon svg={AccordionUp} size={12} color="white" />
                ) : (
                  <Icon svg={AccordionDown} size={12} color="white" />
                )}
              </StyledMixMatchButton>
            )}

            {isPreview && !!promotion.code && (
              <StyledProductPromotionsPreviewCode>
                {t('product->promotionCode')} {promotion.code}
              </StyledProductPromotionsPreviewCode>
            )}
          </StyledProductFooter>
          <div itemProp="offers" itemType="http://schema.org/Offer" itemScope>
            <link itemProp="url" href={as} />
            <meta
              itemProp="availability"
              content={product.outOfStock ? 'https://schema.org/OutOfStock' : 'https://schema.org/InStock'}
            />
            <meta itemProp="priceCurrency" content="SEK" />
            <meta itemProp="price" content={product.priceNoUnit} />
          </div>
          <meta itemProp="description" content={product.productLine2} />
        </StyledProduct>
      </ErrorBoundary>
    );
  }
);

Product.displayName = 'Product';

export default Product;
