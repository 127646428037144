import { MouseEvent, useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks/useAppDispatch';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';
import {
  selectMiniCart,
  selectMiniCartPreviewIsOpen,
  setInfoTooltip,
  setIsCartPreviewOpen,
} from '@slices/miniCartSlice';
import useCustomer from '@hooks/useCustomer';
import Text from '@atoms/Text/Text';
import Tooltip from '@molecules/Tooltip/Tooltip';
import paths from '@constants/paths';
import { convertProductsToAnalyticsEcommerceItems } from '@helpers/analyticsHelpers/analyticsProduct';
import Icon from '@atoms/Icon/Icon';
import {
  StyledCartIconWrapper,
  StyledCounter,
  StyledMiniCart,
  StyledMiniCartButton,
  StyledPrice,
  StyledSpinner,
  StyledTooltip,
} from './MiniCart.styles';
import pushGTMEvent, { EcommerceEvent, pushGTMEcommerceEvent } from '@helpers/analyticsHelpers/pushGTMEvent';
import useResponsive from '@hooks/useResponsive';
import Cart from '@icons/cart.svg';
import useCart from '@hooks/useCart';
import { selectCartUpdating } from '@slices/cartUpdatingSlice';
import useSnackBar from '@hooks/useSnackbar';

const MiniCart = () => {
  const MAX_LOYALTY_TOOLTIP_VIEWS = 3;
  const { cart, isLoading } = useCart();
  const miniCart = useAppSelector(selectMiniCart);
  const { customer, isValidating } = useCustomer();
  const isCartPreviewOpen = useAppSelector(selectMiniCartPreviewIsOpen);
  const dispatch = useAppDispatch();
  const [minimumPurchaseAmount, setMinimumPurchaseAmount] = useState<number>(0);
  const cartUpdating = useAppSelector(selectCartUpdating);
  const router = useRouter();
  const { t } = useTranslation();
  const isCartPage = router.pathname === '/varukorg';
  const { fromDesktop, belowDesktop } = useResponsive();
  const setSnack = useSnackBar();

  const onCloseLoyaltySnackbar = () => {
    const loyaltyTooltipsCount = parseInt(sessionStorage.getItem('loyaltyTooltipsCount') || '0', 10);
    sessionStorage.setItem('loyaltyTooltipsCount', (loyaltyTooltipsCount + 1).toString());
  };

  const miniCartClickHandler = (e: MouseEvent) => {
    if (isCartPage) return;

    if (fromDesktop) {
      pushGTMEvent({
        event: 'Track',
        category: 'minicart',
        action: !isCartPreviewOpen ? 'minicart_opened' : 'minicart_closed',
        label: '',
        value: 0,
      });

      if (!isCartPreviewOpen) {
        pushGTMEcommerceEvent({
          event: EcommerceEvent.VIEW_LIST_ITEM,
          ecommerce: {
            item_list_name: 'minicart',
            items: convertProductsToAnalyticsEcommerceItems(cart?.products || [], undefined, 'minicart'),
          },
        });

        if (cart?.loyaltySaveValue && customer?.isAnonymous) {
          pushGTMEvent({
            event: 'Track',
            category: 'notification',
            action: 'notice_shown_in_minicart',
            label: 'login_to_save',
            value: 0,
          });
        }
      }

      localStorage.setItem('willys.cart-mini', (!isCartPreviewOpen).toString());
      dispatch(setIsCartPreviewOpen(!isCartPreviewOpen, cart));
    } else {
      router.push(paths.CART);
    }
  };

  useEffect(() => {
    dispatch(setIsCartPreviewOpen(localStorage.getItem('willys.cart-mini') === 'true', cart));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (miniCart.tooltip.discount.show && !miniCart.tooltip.info.show)
      setSnack({
        text: t('cartpreview:cart->snackbarYouSave', {
          amount: parseFloat(String(miniCart?.tooltip?.discount.value)).toFixed(2).replace('.', ','),
        }),
        icon: null,
      });
  }, [miniCart.tooltip.discount]);

  useEffect(() => {
    if (!isValidating && customer?.isAnonymous && cart) {
      const { loyaltyProductCount } = cart;

      const loyaltyTooltipsCount = parseInt(sessionStorage.getItem('loyaltyTooltipsCount') || '0', 10);
      const shouldShowLoyaltyTooltip =
        !!loyaltyProductCount &&
        loyaltyProductCount > loyaltyTooltipsCount &&
        loyaltyTooltipsCount < MAX_LOYALTY_TOOLTIP_VIEWS;

      if (shouldShowLoyaltyTooltip) {
        pushGTMEvent({
          event: 'Track',
          category: 'notification',
          action: 'notice_shown_after_add_to_cart',
          label: 'login_to_save',
          value: 0,
        });
      }

      if (shouldShowLoyaltyTooltip && !miniCart.tooltip.info.show) {
        setSnack({
          text: t('cartpreview:cart->snackbarLoyalty'),
          linkText: t('cartpreview:cart->snackbarLoyaltyLink'),
          link: { pathname: router.pathname, query: { ...router.query, login: true } },
          asProp: paths.USER_LOGIN,
          icon: null,
          onClose: onCloseLoyaltySnackbar,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart?.products, isValidating]);

  useEffect(() => {
    if (cart?.removedVouchers && cart.removedVouchers.length) {
      const removedVoucher = cart.removedVouchers.reduce((prev, curr) => {
        return prev.minPurchaseAmount && curr.minPurchaseAmount && prev?.minPurchaseAmount < curr?.minPurchaseAmount
          ? prev
          : curr;
      });
      setMinimumPurchaseAmount(removedVoucher?.minPurchaseAmount as number);
      setSnack({ text: t('tooltip:tooltip->removedVoucher', { minimumPurchaseAmount }), icon: null });
    }
  }, [cart?.removedVouchers]);

  const buttonRef: any = useRef(null);

  return (
    <StyledMiniCart>
      <StyledMiniCartButton
        data-testid="mini-cart-button"
        onClick={miniCartClickHandler}
        disabled={isCartPage}
        aria-label={`Varukorg: ${cart?.totalUnitCount} varor, ${cart?.subTotalWithDiscounts}`}
        ref={buttonRef}
      >
        <StyledCartIconWrapper>
          {!cart || isLoading || cartUpdating?.isUpdating ? (
            <StyledSpinner color={belowDesktop ? 'black' : 'white'} />
          ) : (
            <Icon svg={Cart} size={20} color={belowDesktop ? 'black' : 'white'} data-testid="cart-icon" />
          )}
          <StyledCounter disabled={isCartPage} aria-live="polite">
            {cart?.totalUnitCount}
          </StyledCounter>
        </StyledCartIconWrapper>
        {!!cart?.subTotalWithDiscounts && <StyledPrice aria-live="polite">{cart.subTotalWithDiscounts}</StyledPrice>}
      </StyledMiniCartButton>

      {miniCart?.tooltip?.info?.show && (
        <StyledTooltip
          position="bottom"
          variant="small"
          align="right"
          offsetRef={buttonRef}
          onCloseHandler={() => dispatch(setInfoTooltip({ text: '', show: false }))}
        >
          <Text type="body" size="small" color="white">
            {miniCart.tooltip.info.text === 'nicotine'
              ? t('tooltip:tooltip->nicotineMedicalProduct')
              : miniCart.tooltip.info.text === 'tobacco'
              ? t('tooltip:tooltip->tobaccoProduct')
              : miniCart.tooltip.info.text}
          </Text>
        </StyledTooltip>
      )}
    </StyledMiniCart>
  );
};

export default MiniCart;
