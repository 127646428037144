import styled, { css } from 'styled-components';
import variables from '@styles/variables';
import Config from '@config';
import { rem } from 'polished';

export const StyledBodyContainer = styled.div<{ addBottomPadding?: boolean }>`
  width: 100%;
  ${(props) => props.addBottomPadding && `padding-bottom: ${rem(variables.size.cartPageMobileTotalsHeight)}`};
`;

export const StyledMainContainer = styled.main<{ cartPreview: boolean; sideNav: boolean }>`
  flex-grow: 1;
  max-width: 100%;
  transition: max-width ease ${variables.animation.layout};
  ${(props) =>
    props.cartPreview &&
    !props.sideNav &&
    css`
      ${Config.MEDIA.FROM_DESKTOP} {
        max-width: calc(100% - ${rem(variables.size.cartPreviewWidth)});
      }
    `}
  ${(props) =>
    props.sideNav &&
    !props.cartPreview &&
    css`
      ${Config.MEDIA.FROM_DESKTOP} {
        max-width: calc(100% - ${rem(variables.size.sidenavWidthSmall)});
      }

      ${Config.MEDIA.FROM_DESKTOP_LARGE} {
        max-width: calc(100% - ${rem(variables.size.sidenavWidth)});
      }
    `}
  
  ${(props) =>
    props.sideNav &&
    props.cartPreview &&
    css`
      ${Config.MEDIA.FROM_DESKTOP} {
        max-width: calc(100% - ${rem(variables.size.cartPreviewWidth)} - ${rem(variables.size.sidenavWidthSmall)});
      }

      ${Config.MEDIA.FROM_DESKTOP_LARGE} {
        max-width: calc(100% - ${rem(variables.size.cartPreviewWidth)} - ${rem(variables.size.sidenavWidth)});
      }
    `}
`;

export const StyledMainContainerSection = styled.section`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledContentWrapper = styled.div<{ hide?: boolean }>`
  display: flex;
  position: relative;

  ${(props) =>
    props.hide &&
    css`
      max-height: 0;
      overflow-y: hidden;
    `}
`;
